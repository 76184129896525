.custom-confirm-popup {
	width: 450px;

	// Arrow does not change position, need investigate
	&::after {
		display: none;
		margin-left: -13px;
	}

	&::before {
		display: none;
		margin-left: -15px;
	}

	.p-confirm-popup-content {
		padding: 0;
	}

	.confirm-popup-content {
		display: flex;
		flex-direction: column;
		row-gap: 0.7rem;
		padding: 1.5rem 1rem 0.5rem;
		width: 100%;

		.confirm-popup-icon {
			text-align: center;
			line-height: 1;
			font-size: 3rem;
			color: var(--warning);
		}

		.confirm-popup-message-text {
			text-align: center;
		}

		.confirm-popup-header-text {
			text-align: center;
			font-weight: 600;
			font-size: 1.125rem;
		}
	}

	.p-confirmpopup-footer {
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		column-gap: 2rem;
		padding: 0.5rem 1rem 1.5rem;

		button {
			cursor: pointer;
			white-space: nowrap;
			flex-wrap: nowrap;
			outline: none;
			text-decoration: none;
			height: 35px;
			text-align: center;
			justify-content: center;
			vertical-align: middle;
			background-color: transparent;
			border: 1px solid transparent;
			padding: 0.28rem 0.6rem;
			margin: 0.2rem 0.2rem 0.2rem 0.2rem;
			line-height: 1;
			border-radius: 0.25rem;
			box-shadow: var(--box-shadow-btn);
			font-weight: 700;
			-webkit-transition:
				color 0.15s ease-in-out,
				background-color 0.15s ease-in-out,
				border-color 0.15s ease-in-out,
				-webkit-box-shadow 0.15s ease-in-out;
			transition:
				color 0.15s ease-in-out,
				background-color 0.15s ease-in-out,
				border-color 0.15s ease-in-out,
				-webkit-box-shadow 0.15s ease-in-out;
		}

		.p-confirmpopup-reject-button {
			button {
				color: var(--button-light-text-c);
				background-color: var(--button-light-bgc);
				border-color: var(--button-light-border-c);

				&:not(:disabled):hover {
					color: var(--button-light-hover-text-c);
					background-color: var(--button-light-hover-bgc);
					border-color: var(--button-light-hover-border-c);
				}

				&:not(:disabled):active {
					background-color: var(--button-light-active-bgc);
					border-color: var(--button-light-active-border-c);
				}
			}
		}

		.p-confirmpopup-accept-button {
			button {
				.p-button-label {
					color: var(--button-primary-text-c);
				}

				background-color: var(--button-primary-bgc);
				border-color: var(--button-primary-border-c);

				&:not(:disabled):hover {
					.p-button-label {
						color: var(--button-primary-hover-text-c);
					}
					background-color: var(--button-primary-hover-bgc);
					border-color: var(--button-primary-hover-border-c);
				}

				&:not(:disabled):active {
					background-color: var(--button-primary-active-bgc);
					border-color: var(--button-primary-active-border-c);
				}
			}
		}
	}
}
