//sizes
$s-0: 0;
$s-1: 0.25rem;
$s-2: 0.75rem;
$s-3: 1rem;
$s-4: 1.5rem;
$s-5: 2.5rem;

.ml- {
	&0 {
		margin-left: $s-0 !important;
	}

	&1 {
		margin-left: $s-1 !important;
	}

	&2 {
		margin-left: $s-2 !important;
	}

	&3 {
		margin-left: $s-3 !important;
	}

	&4 {
		margin-left: $s-4 !important;
	}

	&5 {
		margin-left: $s-5 !important;
	}
}

.mr- {
	&0 {
		margin-right: $s-0 !important;
	}

	&1 {
		margin-right: $s-1 !important;
	}

	&2 {
		margin-right: $s-2 !important;
	}

	&3 {
		margin-right: $s-3 !important;
	}

	&4 {
		margin-right: $s-4 !important;
	}

	&5 {
		margin-right: $s-5 !important;
	}
}

.mt- {
	&0 {
		margin-top: $s-0 !important;
	}

	&1 {
		margin-top: $s-1 !important;
	}

	&2 {
		margin-top: $s-2 !important;
	}

	&3 {
		margin-top: $s-3 !important;
	}

	&4 {
		margin-top: $s-4 !important;
	}

	&5 {
		margin-top: $s-5 !important;
	}
}

.mb- {
	&0 {
		margin-bottom: $s-0 !important;
	}

	&1 {
		margin-bottom: $s-1 !important;
	}

	&2 {
		margin-bottom: $s-2 !important;
	}

	&3 {
		margin-bottom: $s-3 !important;
	}

	&4 {
		margin-bottom: $s-4 !important;
	}

	&5 {
		margin-bottom: $s-5 !important;
	}
}

.mx- {
	&0 {
		margin-left: $s-0 !important;
		margin-right: $s-0 !important;
	}

	&1 {
		margin-left: $s-1 !important;
		margin-right: $s-1 !important;
	}

	&2 {
		margin-left: $s-2 !important;
		margin-right: $s-2 !important;
	}

	&3 {
		margin-left: $s-3 !important;
		margin-right: $s-3 !important;
	}

	&4 {
		margin-left: $s-4 !important;
		margin-right: $s-4 !important;
	}

	&5 {
		margin-left: $s-5 !important;
		margin-right: $s-5 !important;
	}
}

.my- {
	&0 {
		margin-top: $s-0 !important;
		margin-bottom: $s-0 !important;
	}

	&1 {
		margin-top: $s-1 !important;
		margin-bottom: $s-1 !important;
	}

	&2 {
		margin-top: $s-2 !important;
		margin-bottom: $s-2 !important;
	}

	&3 {
		margin-top: $s-3 !important;
		margin-bottom: $s-3 !important;
	}

	&4 {
		margin-top: $s-4 !important;
		margin-bottom: $s-4 !important;
	}

	&5 {
		margin-top: $s-5 !important;
		margin-bottom: $s-5 !important;
	}
}

//padding

.pl- {
	&0 {
		padding-left: $s-0 !important;
	}

	&1 {
		padding-left: $s-1 !important;
	}

	&2 {
		padding-left: $s-2 !important;
	}

	&3 {
		padding-left: $s-3 !important;
	}

	&4 {
		padding-left: $s-4 !important;
	}

	&5 {
		padding-left: $s-5 !important;
	}
}

.pr- {
	&0 {
		padding-right: $s-0 !important;
	}

	&1 {
		padding-right: $s-1 !important;
	}

	&2 {
		padding-right: $s-2 !important;
	}

	&3 {
		padding-right: $s-3 !important;
	}

	&4 {
		padding-right: $s-4 !important;
	}

	&5 {
		padding-right: $s-5 !important;
	}
}

.pt- {
	&0 {
		padding-top: $s-0 !important;
	}

	&1 {
		padding-top: $s-1 !important;
	}

	&2 {
		padding-top: $s-2 !important;
	}

	&3 {
		padding-top: $s-3 !important;
	}

	&4 {
		padding-top: $s-4 !important;
	}

	&5 {
		padding-top: $s-5 !important;
	}
}

.pb- {
	&0 {
		padding-bottom: $s-0 !important;
	}

	&1 {
		padding-bottom: $s-1 !important;
	}

	&2 {
		padding-bottom: $s-2 !important;
	}

	&3 {
		padding-bottom: $s-3 !important;
	}

	&4 {
		padding-bottom: $s-4 !important;
	}

	&5 {
		padding-bottom: $s-5 !important;
	}
}

.px- {
	&0 {
		padding-left: $s-0 !important;
		padding-right: $s-0 !important;
	}

	&1 {
		padding-left: $s-1 !important;
		padding-right: $s-1 !important;
	}

	&2 {
		padding-left: $s-2 !important;
		padding-right: $s-2 !important;
	}

	&3 {
		padding-left: $s-3 !important;
		padding-right: $s-3 !important;
	}

	&4 {
		padding-left: $s-4 !important;
		padding-right: $s-4 !important;
	}

	&5 {
		padding-left: $s-5 !important;
		padding-right: $s-5 !important;
	}
}

.py- {
	&0 {
		padding-top: $s-0 !important;
		padding-bottom: $s-0 !important;
	}

	&1 {
		padding-top: $s-1 !important;
		padding-bottom: $s-1 !important;
	}

	&2 {
		padding-top: $s-2 !important;
		padding-bottom: $s-2 !important;
	}

	&3 {
		padding-top: $s-3 !important;
		padding-bottom: $s-3 !important;
	}

	&4 {
		padding-top: $s-4 !important;
		padding-bottom: $s-4 !important;
	}

	&5 {
		padding-top: $s-5 !important;
		padding-bottom: $s-5 !important;
	}
}
