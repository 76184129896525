.page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
	padding-bottom: 4rem;

	footer {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}

footer {
	margin-top: auto;
}

.header-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	column-gap: 2rem;

	.search-with-controls {
		display: flex;
		flex-direction: row;
		column-gap: inherit;
		width: 100%;
	}
}

.delimiter {
	position: relative;
	min-height: 37px;
	background-color: var(--site-body-bgc);
	margin: 0.9rem -0.75rem;
	padding: 0.5rem;
	box-shadow: var(--box-shadow-delimiter);

	&[data-height-0] {
		min-height: 0;
	}
}
