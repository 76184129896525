//Buttons, links, action list containers

.rfid-btn {
	cursor: pointer;
	width: fit-content;
	user-select: none;
	display: inline-flex;
	white-space: nowrap;
	flex-wrap: nowrap;
	align-items: center;
	outline: none;
	text-decoration: none;
	height: 35px;
	text-align: center;
	justify-content: center;
	vertical-align: middle;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.28rem 0.6rem;
	margin: 0.2rem 0.2rem 0.2rem 0.2rem;
	line-height: 1;
	border-radius: 0.25rem;
	box-shadow: var(--box-shadow-btn);
	font-weight: 700;
	-webkit-transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;

	i {
		font-size: 1.3rem;
	}

	&:not(:disabled):focus,
	&:not(:disabled):active:focus {
		-webkit-box-shadow: var(--box-shadow-btn-focus);
		box-shadow: var(--box-shadow-btn-focus);
	}

	&:disabled {
		background-color: var(--button-disabled-bgc);
		color: var(--button-disabled-text-c);
		border-color: var(--button-disabled-border-c);
	}

	&.rfid-icon-btn {
		border: none;
		box-shadow: none !important;
	}
}

.rfid-icon-btn {
	color: var(--icon-button-text-c);
	background-color: var(--icon-button-bgc);

	&.rfid-icon-btn:not(:disabled):hover {
		background-color: var(--icon-button-hover-bgc);
	}
}

.rfid-btn-light {
	color: var(--button-light-text-c);
	background-color: var(--button-light-bgc);
	border-color: var(--button-light-border-c);

	&:not(:disabled):hover {
		color: var(--button-light-hover-text-c);
		background-color: var(--button-light-hover-bgc);
		border-color: var(--button-light-hover-border-c);
	}

	&:not(:disabled):active {
		background-color: var(--button-light-active-bgc);
		border-color: var(--button-light-active-border-c);
	}
}

.rfid-btn-primary {
	color: var(--button-primary-text-c);
	background-color: var(--button-primary-bgc);
	border-color: var(--button-primary-border-c);

	&:not(:disabled):hover {
		color: var(--button-primary-hover-text-c);
		background-color: var(--button-primary-hover-bgc);
		border-color: var(--button-primary-hover-border-c);
	}

	&:not(:disabled):active {
		background-color: var(--button-primary-active-bgc);
		border-color: var(--button-primary-active-border-c);
	}
}

.rfid-btn-danger {
	color: var(--button-danger-text-c);
	background-color: var(--button-danger-bgc);
	border-color: var(--button-danger-border-c);

	&:not(:disabled):hover {
		color: var(--button-danger-hover-text-c);
		background-color: var(--button-danger-hover-bgc);
		border-color: var(--button-danger-hover-border-c);
	}

	&:not(:disabled):active {
		background-color: var(--button-danger-active-bgc);
		border-color: var(--button-danger-active-border-c);
	}
}

.rfid-btn-list {
	display: flex;
	gap: 0.6rem;
	min-height: 35px;
	flex-wrap: nowrap;
	justify-content: flex-end;

	> span {
		display: inline-block;
	}

	.rfid-btn {
		margin-left: 0.6rem;
	}

	&.list-direction-column {
		flex-direction: column;
	}
}

.icon-list {
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
}

.action-icon,
.action-link {
	cursor: pointer;
	background: transparent;
	border: transparent;
	color: var(--primary);
	font-size: 1.125rem;
	line-height: 1;
	display: inline-flex;
	height: 100%;
	padding: 6px 6px;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	transition: background-color 0.15s ease;

	&:hover {
		color: var(--primary);
		background-color: var(--table-row-action-hover-bgc);
	}
}

.action-link {
	font-size: 0.9rem;
}

.static-icon {
	font-size: 1.125rem;
}

.close-icon {
	line-height: 1;
	font-size: 1.125rem;
	color: var(--close-icon-c);
	cursor: pointer;

	&:hover {
		color: var(--text-dark);
	}
}
