textarea:hover {
	border-color: var(--input-border-hover-c) !important;
}

textarea:focus-visible {
	outline: none;
}

.input-list-column {
	display: flex;
	flex-direction: column;
	row-gap: 0.7rem;
}

.double-control {
	display: flex;
	width: 100%;
	max-width: 600px;
	gap: 0.7rem;
}

.control-input-container {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	height: 35px;
	width: 100%;
	max-width: 300px;
	min-width: 200px;

	.p-focus:focus {
		border: none;
	}

	.p-inputtext:enabled:focus {
		border-color: var(--input-border-hover-c);
	}

	.input-box {
		display: flex;
		flex: 1 1 auto;

		&.readonly {
			> span {
				height: 35px;
				white-space: nowrap;
				padding: 0.4rem 0.8rem;
				border: 1px solid var(--input-readonly-border-c);
				color: var(--input-readonly-text-c);
				background-color: var(--input-readonly-bgc);
				border-left: none;
				border-radius: 0 4px 4px 0;
				width: 0;
				flex: 1 1 auto;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 0.875rem;
			}
		}

		input[type='checkbox'] {
			height: 20px;
			width: 20px;
			border-radius: 4px;
			margin-left: 0.6rem;
		}

		input:not([type='checkbox']):not([type='radio']) {
			width: 0;
			flex: 1 1 auto;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	label {
		white-space: nowrap;
		flex-shrink: 0;
		line-height: 1.7;
		font-weight: 600;
		color: var(--secondary);
		background-color: var(--input-label-bgc);
		padding: 0.28rem 0.8rem;
		height: 100%;
		border: 1px solid var(--input-border-c);
		border-radius: 4px 0 0 4px;

		&::after {
			content: ':';
		}
	}

	&.textarea {
		height: auto;

		textarea {
			width: 100%;
		}
	}
}

.required {
	label {
		&::after {
			content: '' !important;
		}
	}

	span.required-marker {
		&::after {
			content: '*';
			color: var(--input-required-marker-c);
		}

		&::before {
			content: ':';
		}
	}
}

.input-component-wrapper {
	position: relative;
	width: 100%;
	max-width: 300px;
	min-width: 200px;

	.control-input-container {
		max-width: inherit;
		min-width: inherit;
	}
}

.control-input-counter {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	gap: 0.5rem;

	.p-inputnumber-input {
		max-width: 65px;
	}
}

.control-input-checkbox {
	position: relative;
	display: flex;
	align-items: center;
	width: fit-content;

	p-checkbox {
		display: contents;
	}

	label {
		white-space: nowrap;
		padding-left: 27px;
		line-height: 1.4;
	}

	&.disabled {
		label {
			color: var(--text-offline-state);
		}
	}

	.p-checkbox,
	.p-radiobutton {
		position: absolute;
	}

	.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) {
		.p-radiobutton-box {
			border-color: var(--input-border-hover-c);
		}
	}

	i.mdi-close {
		margin-left: 0.2rem;
	}
}

.p-radiobutton-checked {
	.p-radiobutton-box {
		border-color: var(--checkbox-checked-border-c);
		background: var(--checkbox-checked-bgc);
	}
}

.p-checkbox-checked {
	.p-checkbox-box {
		border-color: var(--checkbox-checked-border-c);
		background: var(--checkbox-checked-bgc);
	}
}

.checkbox-list {
	display: flex;
	gap: 1.5rem;
}

.control-input:not([type='checkbox']) {
	min-height: 35px;
	padding: 0.28rem 0.8rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0 4px 4px 0;
	border: 1px solid var(--input-border-c);
	border-left: none;
	transition: border-color 0.2s ease;

	&:not([disabled]):hover {
		border-color: var(--input-border-hover-c);
	}

	&.without-label {
		border-left: 1px solid var(--input-border-c);
		border-radius: 4px;
	}
}

.p-select,
.p-multiselect {
	border: 1px solid var(--input-border-c);
	border-left: none;
	border-radius: 0 4px 4px 0;
	height: 35px;
	width: 0;
	flex: 1 1 auto;
	align-items: center;

	&.p-disabled {
		background: var(--input-readonly-bgc);

		span {
			line-height: 1;
			color: var(--input-readonly-text-c);
			background-color: var(--input-readonly-bgc);
		}
	}

	.p-inputtext,
	input {
		font: inherit !important;
	}

	&:not(:disabled):hover {
		border-color: var(--input-border-hover-c);
	}

	.p-multiselect-filter-container {
		display: flex;
	}
}

.p-autocomplete {
	height: 35px;
	width: 0;
	flex: 1 1 auto;
	align-items: center;

	.p-autocomplete-input {
		border-radius: 0 4px 4px 0;
		padding-right: 2.2rem;
		border-left: 0;
		height: inherit;
	}

	.p-autocomplete-multiple-container {
		width: 100%;
	}

	&.p-autocomplete-clearable {
		.p-inputtext {
			padding-right: 3rem;
		}
	}
}

.p-inputtext:enabled:hover {
	border-color: var(--input-border-hover-c);
}

.p-autocomplete-dropdown {
	background-color: transparent;
	border: none;
	border-radius: 0;
}

.p-autocomplete-dropdown,
.p-multiselect-dropdown,
.p-select-dropdown {
	position: absolute;
	right: 8px;
	width: auto;
	color: var(--internal-button-c);

	&:hover {
		color: var(--internal-button-hover-c);
		background-color: var(--internal-button-bgc);
	}

	&:not(:disabled):active,
	&:not(:disabled):focus {
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.p-icon {
		height: 14px;
		width: 14px;
	}
}

.p-multiselect-clear-icon,
.p-autocomplete-clear-icon {
	position: absolute;
	right: 27px;
	height: 14px;
	width: 14px;
	color: var(--internal-button-c);

	&:hover {
		color: var(--internal-button-hover-c);
		background-color: var(--internal-button-bgc);
	}

	&:not(:disabled):active,
	&:not(:disabled):focus {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

.p-select-overlay {
	.p-select-list {
		.p-select-option {
			color: var(--input-dropdown-option-text-c);

			&:not(.p-disabled) {
				&:hover {
					background: var(--input-dropdown-option-hover-bgc);
				}

				&.p-select-option-selected:hover {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}

			&.p-select-option-selected {
				background: var(--item-keyboard-select-bgc);

				&.p-focus {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}
		}
	}
}

.p-autocomplete-overlay {
	width: 0;
	flex-grow: 1;

	.p-autocomplete-list {
		.p-autocomplete-option-group {
			font-weight: 700;
		}

		.p-autocomplete-option {
			color: var(--input-dropdown-option-text-c);

			&:not(.p-disabled) {
				&:hover {
					background: var(--input-dropdown-option-hover-bgc);
				}

				&.p-autocomplete-option-selected:hover {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}

			&.p-autocomplete-option-selected {
				background: var(--item-keyboard-select-bgc);

				&.p-focus {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}

			span {
				word-break: break-word;
				white-space: normal;
			}
		}
	}
}

.p-multiselect-overlay {
	.p-multiselect-header {
		.p-multiselect-filter-container {
			p-iconfield {
				display: flex;
				flex: 1 1 auto;
			}
		}
	}

	.p-multiselect-option-list {
		.p-multiselect-option {
			color: var(--input-dropdown-option-text-c);

			&:not(.p-disabled) {
				&:hover {
					background: var(--input-dropdown-option-hover-bgc);
				}

				&.p-multiselect-option-selected:hover {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}

			&.p-multiselect-option-selected {
				background: var(--item-keyboard-select-bgc);

				&.p-focus {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}
		}
	}
}

.p-select-overlay,
.p-autocomplete-overlay,
.p-multiselect-overlay {
	border-radius: 4px;
}

.without-label,
.label-top {
	.input-box {
		> input,
		> textarea {
			border-left: 1px solid var(--input-border-c);
			border-radius: 4px;
		}

		> textarea {
			border-left: 1px solid var(--input-border-c);
			border-radius: 4px;
		}

		&.readonly {
			span {
				border-left: 1px solid var(--input-border-c);
				border-radius: 4px;
			}
		}
	}

	.p-select,
	.p-autocomplete {
		border-radius: 4px;
	}

	.p-select {
		border-left: 1px solid var(--input-border-c);

		&:hover {
			border-color: var(--input-border-hover-c);
		}
	}

	.p-autocomplete {
		.p-autocomplete-input {
			border-left: 1px solid var(--input-border-c);
			border-radius: 4px;

			&:hover {
				border-color: var(--input-border-hover-c);
			}
		}
	}

	.p-multiselect {
		border-left: 1px solid var(--input-border-c);
		border-radius: 4px;

		&:not(.p-disabled):hover {
			border-color: var(--input-border-hover-c);
		}
	}

	.skeleton {
		width: 100%;
		border-radius: 4px !important;
		border-left: 1px solid var(--input-border-c) !important;
	}
}

.label-top {
	.control-input-container,
	&.control-input-container {
		margin-top: 1.3rem;
	}

	label {
		height: auto;
		position: absolute;
		top: -21px;
		left: 0;
		line-height: 1;
		padding: 0;
		background: inherit;
		border: 0;
		color: var(--input-label-text-c);
	}
}

.switcher-container {
	display: flex;
	flex-wrap: nowrap;
	height: fit-content;
	column-gap: 0.7rem;

	label {
		position: relative;
		max-width: 180px;
	}

	.p-toggleswitch {
		width: 2.5rem;
		height: 1.25rem;
		vertical-align: text-top;

		&.p-toggleswitch-checked {
			.p-toggleswitch-slider {
				background: var(--toggle-switch-checked-bgc);
				border-color: var(--toggle-switch-checked-border-c);
			}
		}
	}
}

.p-overlay {
	.p-overlay-content {
		display: flex;
	}
}

.p-popover {
	border: 1px solid var(--border-c);
	box-shadow: var(--box-shadow-overlay-p);

	&::before {
		border-bottom-color: var(--border-c);
	}

	.p-popover-content {
		width: 400px;
	}

	&.custom-position {
		margin-left: 0;
		margin-bottom: 10px;

		&::before {
			top: -8px;
			right: 25px;
			left: auto;
			rotate: 45deg;
			bottom: auto;
			width: 15px;
			height: 15px;
			border: 1px solid black;
			border-color: #c9ceda transparent transparent #c9ceda;
			background-color: #fff;
		}

		&::after {
			top: 8px;
			right: 23px;
			rotate: 180deg;
			bottom: auto;
			border-color: transparent;
		}
	}
}

.overlay-custom-position-wrapper {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	width: 0;
	flex: 1 1 auto;

	.overlay-target {
		height: 0;
		position: absolute;
		right: 0;
		top: 100%;
	}
}

.add-input {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	column-gap: 0.7rem;
}

.input-icon-absolute-right {
	position: relative;

	i {
		line-height: 1;
		position: absolute;
		right: 0.5rem;
		top: 50%;
		transform: translate(0, -50%);
	}

	input.control-input {
		padding-right: 2rem;
	}
}
