//Features, spacings, and rarely used styles

@use './spacing';

.offline {
	color: var(--offline) !important;
}

.success {
	color: var(--success) !important;
}

.danger {
	color: var(--danger) !important;
}

.warning {
	color: var(--warning) !important;
}

.secondary {
	color: var(--secondary) !important;
}

.light-grey-text {
	color: var(--text-light-grey);
}

.icon-size-normal {
	font-size: 1.3rem;
}

.rotate {
	&-90 {
		rotate: 90deg;
	}

	&-180 {
		rotate: 180deg;
	}

	&-270 {
		rotate: 270deg;
	}
}

.text-info-description {
	color: var(--secondary);
	font-size: 0.9rem;

	&.small {
		font-size: 0.8rem;
	}
}

.description {
	p {
		font-weight: 600;
		font-size: 0.9rem;
		margin-bottom: 1rem;
		color: var(--dark);

		&:last-of-type {
			margin-bottom: 0;
		}

		span.label {
			font-weight: 600;
			margin-right: 0.5rem;
			color: var(--text-light-grey);
		}
	}
}

.marker {
	position: absolute;
	display: block;
	background-color: var(--marker-default-c);
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	height: 86%;
	width: 6px;
}

.under-construction {
	display: flex;
	align-items: center;
	flex-direction: column;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	background: var(--border-c);
	opacity: 0.5;

	i {
		font-size: 4rem;
	}

	span {
		position: relative;
		color: black;

		&::after {
			content: 'Will be added later';
			bottom: 0px;
			left: -30px;
			position: absolute;
			white-space: nowrap;
		}
	}
}

.justify-center {
	justify-content: center;
}

.cursor-default {
	cursor: default !important;
}

.d-none {
	display: none;
}

.overflow-all-visible {
	overflow: visible !important;
	overflow-y: visible !important;
	overflow-x: visible !important;
}

.text-nowrap {
	white-space: nowrap;
}

.t-right {
	text-align: right !important;
}

.t-left {
	text-align: left !important;
}

.t-center {
	text-align: center !important;
}

.t-nowrap {
	white-space: nowrap !important;
}
