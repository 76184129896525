.p-tooltip {
	text-align: center;
	padding: 0;

	.p-tooltip-arrow {
		scale: 1;
		position: absolute;
		width: 0.482rem;
		height: 0.482rem;
		rotate: 45deg;
		background: var(--tooltip-bgc);
		border: none;

		&::after {
			content: '';
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: transparent;
			border: 1px solid transparent;
		}
	}

	&.p-tooltip-right {
		padding-left: 0.34rem;

		.p-tooltip-arrow {
			top: 50%;
			left: 2px;

			&::after {
				border-color: transparent transparent var(--tooltip-border-c) var(--tooltip-border-c);
			}
		}
	}

	&.p-tooltip-left {
		padding-right: 0.34rem;

		.p-tooltip-arrow {
			top: 50%;
			right: 2px;

			&::after {
				border-color: var(--tooltip-border-c) var(--tooltip-border-c) transparent transparent;
			}
		}
	}

	&.p-tooltip-top {
		padding-bottom: 0.34rem;

		.p-tooltip-arrow {
			bottom: 2px;
			left: 50%;

			&::after {
				border-color: transparent var(--tooltip-border-c) var(--tooltip-border-c) transparent;
			}
		}
	}

	&.p-tooltip-bottom {
		padding-top: 0.34rem;

		.p-tooltip-arrow {
			top: 2px;
			left: 50%;

			&::after {
				border-color: var(--tooltip-border-c) transparent transparent var(--tooltip-border-c);
			}
		}
	}

	.p-tooltip-text {
		border: 1px solid var(--tooltip-border-c);
		border-radius: 4px;
		padding: 0.25rem 0.5rem;
		box-shadow: none;
		background-color: var(--tooltip-bgc);
		color: var(--tooltip-text-c);
	}
}
