//Base styles for tags

html {
	font-family: sans-serif;
	position: relative;
	min-height: 100%;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

//scroll for firefox
@-moz-document url-prefix() {
	* {
		scrollbar-color: var(--scroll-thumb-bg) var(--scroll-bar-bg);
		scrollbar-width: thin;
	}
}

//scroll for webkit
*::-webkit-scrollbar {
	width: 5px;
	height: 8px;
	background-color: var(--scroll-bar-bg);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--scroll-thumb-bg);
	border-radius: 9em;
}

body {
	margin: 0;
	font-family: 'Nunito Variable';
	font-size: 0.9rem;
	box-sizing: border-box;
	background-color: var(--site-body-bgc);
	color: var(--secondary);
}

p {
	margin: 0;
}

input:focus-visible {
	box-shadow: none;
	outline: none;
}

.p-component {
	font-family: inherit;
	font-size: inherit;
}

hr {
	color: var(--border-c);
	border-color: var(--border-c);
	opacity: 1;
	margin: 0.75rem -12px;

	&.without-margin {
		margin: 0;
	}
}

button {
	padding: 0;
}

button:not(:disabled),
[type='button']:not(:disabled) {
	cursor: pointer;
}

small {
	font-size: 0.7rem;
}

h1 {
	font-size: 2.1rem;
}

h2 {
	font-size: 1.8rem;
}

h3 {
	font-size: 1.25rem;
}

h5 {
	font-size: 1.1rem;
}

h6 {
	font-size: 0.9rem;
}

h6,
h5,
h4,
h3,
h2,
h1 {
	margin: 0;
	font-weight: 700;
	line-height: 1.2;
}

a,
a:not([href]):not([class]) {
	cursor: pointer;
	color: var(--link-text-c);
	text-decoration: none;
	outline: none;

	&:hover {
		color: var(--link-hover-text-c);
	}
}

ul {
	padding: 0;
	margin: 0;

	li {
		list-style: none;
	}
}

img,
svg {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
}

app-search,
labeled-input,
app-input,
p-autocomplete,
p-radiobutton,
customer-selector,
customer-filter,
location-selector,
product-selector,
location-filter,
manager-customer-selector,
app-button {
	display: contents;
}

input:disabled {
	color: var(--input-readonly-text-c);
	background-color: var(--input-readonly-bgc);
}

//progress bar
progress {
	appearance: none;
	width: 100%;
	height: 7px;
	border-radius: 4px;
	background-color: var(--progress-bgc);

	&::-webkit-progress-bar {
		border-radius: 4px;
		background-color: var(--progress-bgc);
	}

	//webkit (chrome, safari)
	&.shipped::-webkit-progress-value {
		border-radius: 4px;
		background-color: var(--progress-value-shipped-bgc);
	}

	&.received::-webkit-progress-value {
		border-radius: 4px;
		background-color: var(--progress-value-received-bgc);
	}

	//firefox
	&.shipped::-moz-progress-bar {
		border-radius: 4px;
		background-color: var(--progress-value-shipped-bgc);
	}

	&.received::-moz-progress-bar {
		border-radius: 4px;
		background-color: var(--progress-value-received-bgc);
	}
}
