.badge-container {
	display: flex;
	flex-wrap: wrap;
	row-gap: 0.5rem;
	column-gap: 0.5rem;
	width: 100%;
}

.badge {
	font-weight: 600;
	padding: 0.35rem 0.6rem;
	line-height: 1;
	font-size: 0.8rem;
	text-align: center;
	border-radius: 4px;
	white-space: nowrap;
	height: 1.5rem;
	color: var(--text-secondary);

	//add position to corner badge (top, bottom => left/right)
	&.corner-badge {
		position: absolute;
		padding: 0.35rem;
		border-radius: 0;

		&.top-left {
			left: 0;
			top: 0;
			border-bottom-right-radius: 50%;
			border-top-left-radius: 4px;
		}
	}

	&.badge-grey {
		color: var(--badge-grey-text-c);
		background-color: var(--badge-grey-bgc);
	}

	&.badge-warning {
		color: var(--badge-warning-text-c);
		background-color: var(--badge-warning-bgc);
	}

	&.badge-info {
		color: var(--badge-info-text-c);
		background-color: var(--badge-info-bgc);
	}

	&.badge-dark-blue {
		color: var(--badge-dark-blue-text-c);
		background-color: var(--badge-dark-blue-bgc);
	}

	&.badge-danger {
		color: var(--badge-danger-text-c);
		background-color: var(--badge-danger-bgc);
	}

	&.badge-primary {
		color: var(--badge-primary-text-c);
		background-color: var(--badge-primary-bgc);
	}

	&.badge-sld-layout {
		color: var(--badge-sld-layout-text-c);
		background-color: var(--badge-sld-layout-bgc);
	}

	&.badge-zpl-layout {
		color: var(--badge-zpl-layout-text-c);
		background-color: var(--badge-zpl-layout-bgc);
	}
}
