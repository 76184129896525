/* nunito-cyrillic-ext-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-cyrillic-ext-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* nunito-cyrillic-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-cyrillic-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* nunito-vietnamese-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-vietnamese-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* nunito-latin-ext-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-latin-ext-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* nunito-latin-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-latin-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* nunito-cyrillic-ext-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-cyrillic-ext-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* nunito-cyrillic-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-cyrillic-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* nunito-vietnamese-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-vietnamese-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* nunito-latin-ext-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-latin-ext-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* nunito-latin-wght-normal */
@font-face {
  font-family: "Nunito Variable";
  font-style: normal;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-latin-wght-normal.woff2) format("woff2-variations");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* nunito-cyrillic-ext-wght-italic */
@font-face {
  font-family: "Nunito Variable";
  font-style: italic;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-cyrillic-ext-wght-italic.woff2) format("woff2-variations");
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* nunito-cyrillic-wght-italic */
@font-face {
  font-family: "Nunito Variable";
  font-style: italic;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-cyrillic-wght-italic.woff2) format("woff2-variations");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* nunito-vietnamese-wght-italic */
@font-face {
  font-family: "Nunito Variable";
  font-style: italic;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-vietnamese-wght-italic.woff2) format("woff2-variations");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* nunito-latin-ext-wght-italic */
@font-face {
  font-family: "Nunito Variable";
  font-style: italic;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-latin-ext-wght-italic.woff2) format("woff2-variations");
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* nunito-latin-wght-italic */
@font-face {
  font-family: "Nunito Variable";
  font-style: italic;
  font-display: swap;
  font-weight: 200 1000;
  src: url(./files/nunito-latin-wght-italic.woff2) format("woff2-variations");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
:root {
  --primary: #558ed5;
  --secondary: #6c757d;
  --light: #eef2f7;
  --success: #0acf97;
  --danger: #fa5c7c;
  --info: #39b3d7;
  --dark: #313a46;
  --warning: #ffa800;
  --white: white;
  --line-hover-bgc: #f7f8fb;
  --border-c: #c9ceda;
  --offline: #c9ceda;
  --close-icon-c: #6c757d;
  --image-box-bgc: #f7f8fb;
  --row-expand-bgc: #f7f8fb;
  --text-offline-state: #98a1aa;
  --text-light-grey: #98a1aa;
  --text-dark: var(--dark);
  --text-secondary: var(--secondary);
  --text-primary: var(--primary);
  --text-light-primary: #99d5fb;
  --table-header-bgc: #eef2f7;
  --table-header-text-c: var(--text-secondary);
  --table-second-header-bgc: #f8fafc;
  --table-row-text-c: var(--text-secondary);
  --table-row-border-c: #eef2f7;
  --table-row-hover-bgc: var(--line-hover-bgc);
  --table-row-hover-text-c: var(--text-dark);
  --table-row-img-box-border-c: var(--border-c);
  --table-row-img-box-icon-c: var(--text-light-grey);
  --table-row-action-hover-bgc: #e8f6ff;
  --table-row-action-hover-text-c: var(--text-primary);
  --table-row-action-text-c: var(--text-light-primary);
  --table-row-action-selected-bgc: #f4fafe;
  --item-selected-bgc: #f4fafe;
  --item-hover-bgc: #f7f8fb;
  --item-selected-box-shadow: inset 0 0 0 2px rgba(0, 151, 244, 0.4);
  --item-keyboard-select-bgc: #f4fafe;
  --item-keyboard-select-hover-bgc: #e8f6ff;
  --tab-menu-border-c: var(--border-c);
  --tab-menu-text-c: var(--text-secondary);
  --tab-menu-link-bgc: transparent;
  --tab-menu-link-active-bgc: white;
  --link-text-c: var(--text-primary);
  --link-hover-text-c: #3858a6;
  --tooltip-bgc: white;
  --tooltip-border-c: #313a46;
  --tooltip-text-c: var(--text-secondary);
  --accordion-header-bg: #eef2f7;
  --scroll-bar-bg: #fafbff;
  --scroll-thumb-bg: #c9ceda;
  --progress-bgc: #eef2f7;
  --progress-value-received-bgc: #39b3d7;
  --progress-value-shipped-bgc: #ffa800;
  --designer-build-block-bgc: white;
  --designer-build-block-hover-bgc: var(--line-hover-bgc);
  --designer-canvas-wrapper-bgc: #eef2f7;
  --message-summary-text-c: var(--text-dark);
  --message-detail-text-c: var(--text-secondary);
  --message-close-icon-c: var(--close-icon-c);
  --message-success-bgc: #eefffa;
  --message-success-border-c: var(--success);
  --message-success-icon-c: var(--success);
  --message-error-bgc: #fff4f6;
  --message-error-border-c: var(--danger);
  --message-error-icon-c: var(--danger);
  --message-warning-bgc: #fff9ed;
  --message-warning-border-c: var(--warning);
  --message-warning-icon-c: var(--warning);
  --content-page-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.0784313725);
  --site-body-bgc: #fafbff;
  --input-border-c: var(--border-c);
  --input-border-hover-c: var(--primary);
  --input-label-bgc: #f7f8fb;
  --input-label-text-c: var(--text-light-grey);
  --input-required-marker-c: var(--danger);
  --input-dropdown-option-text-c: #313a46;
  --input-dropdown-option-hover-bgc: #f7f8fb;
  --input-dropdown-option-focus-bgc: white;
  --input-readonly-border-c: var(--border-c);
  --input-readonly-text-c: var(--text-dark);
  --input-readonly-bgc: #f7f8fb;
  --toggle-switch-checked-bgc: var(--primary);
  --toggle-switch-checked-border-c: transparent;
  --checkbox-checked-bgc: var(--primary);
  --checkbox-checked-border-c: var(--primary);
  --badge-grey-bgc: #f7f8fb;
  --badge-grey-text-c: #98a1aa;
  --badge-danger-bgc: #fff4f6;
  --badge-danger-text-c: #fa5c7c;
  --badge-violet-bgc: #f3f2fe;
  --badge-violet-text-c: #504cf4;
  --badge-warning-bgc: #fff9ed;
  --badge-warning-text-c: #ffa800;
  --badge-info-bgc: #f1f9fc;
  --badge-info-text-c: #39b3d7;
  --badge-success-bgc: #eefffa;
  --badge-success-text-c: #0acf97;
  --badge-dark-blue-bgc: #f1f3f9;
  --badge-dark-blue-text-c: #3858a6;
  --badge-primary-bgc: #e4f0ff;
  --badge-primary-text-c: #558ed5;
  --badge-zpl-layout-text-c: #313a46;
  --badge-zpl-layout-bgc: #fede83;
  --badge-sld-layout-text-c: #313a46;
  --badge-sld-layout-bgc: #afe7f8;
  --marker-default-c: #f7f8fb;
  --marker-user-permission: #558ed5;
  --marker-order-transfer: #e4f0ff;
  --marker-order-purchase: #ebd8ff;
  --marker-order-sales: #ffebd8;
  --marker-primary: #558ed5;
  --button-primary-bgc: #558ed5;
  --button-primary-text-c: white;
  --button-primary-border-c: #558ed5;
  --button-primary-hover-bgc: #504cf4;
  --button-primary-hover-text-c: white;
  --button-primary-hover-border-c: #504cf4;
  --button-primary-active-bgc: #504cf4;
  --button-primary-active-border-c: #504cf4;
  --button-light-bgc: #eef2f7;
  --button-light-text-c: var(--text-primary);
  --button-light-border-c: #eef2f7;
  --button-light-hover-bgc: #c9ceda;
  --button-light-hover-text-c: var(--text-dark);
  --button-light-hover-border-c: #c9ceda;
  --button-light-active-bgc: #c9ceda;
  --button-light-active-border-c: #c9ceda;
  --button-danger-bgc: var(--danger);
  --button-danger-text-c: white;
  --button-danger-border-c: var(--danger);
  --button-danger-hover-bgc: #f9375e;
  --button-danger-hover-text-c: white;
  --button-danger-hover-border-c: #f9375e;
  --button-danger-active-bgc: #f9375e;
  --button-danger-active-border-c: #f9375e;
  --button-disabled-bgc: #eef2f7;
  --button-disabled-text-c: var(--text-light-grey);
  --button-disabled-border-c: #eef2f7;
  --icon-button-bgc: transparent;
  --icon-button-text-c: #558ed5;
  --icon-button-hover-bgc: #f4fafe;
  --internal-button-bgc: transparent;
  --internal-button-c: #98a1aa;
  --internal-button-hover-c: #313a46;
  --box-shadow-btn: 0 2px 4px 1px rgba(56, 88, 166, 0.2);
  --box-shadow-btn-focus: 0 0 0 3px rgba(0, 151, 244, 0.4);
  --box-shadow-card: 0 4px 20px 0 rgba(56, 88, 166, 0.2);
  --box-shadow-sidebar: 0 0 30px 7px rgba(56, 88, 166, 0.1607843137);
  --box-shadow-overlay-p: 0 2px 6px 0 rgba(56, 88, 166, 0.2);
  --box-shadow-confirm-msg: 0 0 30px 7px rgba(56, 88, 166, 0.1607843137);
  --box-shadow-site-header: 0 4px 6px 0 rgba(0, 0, 0, 0.1019607843);
  --box-shadow-delimiter: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.1019607843);
  --box-shadow-left-menu: 1px 2px 2px 0px rgba(0, 0, 0, 0.0509803922);
  --breadcrumb-separator-c: #98a1aa;
  --breadcrumb-text-c: #98a1aa;
  --breadcrumb-active-text-c: #6c757d;
  --menu-item-c: #6c757d;
  --menu-item-hover-bgc: #f7f8fb;
  --left-menu-bgc: white;
  --left-menu-item-text-c: var(--text-secondary);
  --left-menu-item-active-text-c: #3858a6;
  --left-menu-item-active-bgc: #e8f6ff;
  --left-menu-item-hover-text-c: var(--text-secondary);
  --left-menu-item-hover-bgc: #f7f8fb;
  --left-menu-customer-block-text-c: var(--text-secondary);
  --left-menu-customer-block-bgc: white;
}

html {
  font-family: sans-serif;
  position: relative;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@-moz-document url-prefix() {
  * {
    scrollbar-color: var(--scroll-thumb-bg) var(--scroll-bar-bg);
    scrollbar-width: thin;
  }
}
*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--scroll-bar-bg);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-bg);
  border-radius: 9em;
}

body {
  margin: 0;
  font-family: "Nunito Variable";
  font-size: 0.9rem;
  box-sizing: border-box;
  background-color: var(--site-body-bgc);
  color: var(--secondary);
}

p {
  margin: 0;
}

input:focus-visible {
  box-shadow: none;
  outline: none;
}

.p-component {
  font-family: inherit;
  font-size: inherit;
}

hr {
  color: var(--border-c);
  border-color: var(--border-c);
  opacity: 1;
  margin: 0.75rem -12px;
}
hr.without-margin {
  margin: 0;
}

button {
  padding: 0;
}

button:not(:disabled),
[type=button]:not(:disabled) {
  cursor: pointer;
}

small {
  font-size: 0.7rem;
}

h1 {
  font-size: 2.1rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.1rem;
}

h6 {
  font-size: 0.9rem;
}

h6,
h5,
h4,
h3,
h2,
h1 {
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
}

a,
a:not([href]):not([class]) {
  cursor: pointer;
  color: var(--link-text-c);
  text-decoration: none;
  outline: none;
}
a:hover,
a:not([href]):not([class]):hover {
  color: var(--link-hover-text-c);
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}

img,
svg {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

app-search,
labeled-input,
app-input,
p-autocomplete,
p-radiobutton,
customer-selector,
customer-filter,
location-selector,
product-selector,
location-filter,
manager-customer-selector,
app-button {
  display: contents;
}

input:disabled {
  color: var(--input-readonly-text-c);
  background-color: var(--input-readonly-bgc);
}

progress {
  appearance: none;
  width: 100%;
  height: 7px;
  border-radius: 4px;
  background-color: var(--progress-bgc);
}
progress::-webkit-progress-bar {
  border-radius: 4px;
  background-color: var(--progress-bgc);
}
progress.shipped::-webkit-progress-value {
  border-radius: 4px;
  background-color: var(--progress-value-shipped-bgc);
}
progress.received::-webkit-progress-value {
  border-radius: 4px;
  background-color: var(--progress-value-received-bgc);
}
progress.shipped::-moz-progress-bar {
  border-radius: 4px;
  background-color: var(--progress-value-shipped-bgc);
}
progress.received::-moz-progress-bar {
  border-radius: 4px;
  background-color: var(--progress-value-received-bgc);
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.75rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.ml-5 {
  margin-left: 2.5rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.75rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mr-5 {
  margin-right: 2.5rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.75rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 2.5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.75rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 2.5rem !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx-2 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.pl-2 {
  padding-left: 0.75rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.pl-4 {
  padding-left: 1.5rem !important;
}
.pl-5 {
  padding-left: 2.5rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.pr-2 {
  padding-right: 0.75rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.pr-4 {
  padding-right: 1.5rem !important;
}
.pr-5 {
  padding-right: 2.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.75rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pt-5 {
  padding-top: 2.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.75rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 2.5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px-2 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.offline {
  color: var(--offline) !important;
}

.success {
  color: var(--success) !important;
}

.danger {
  color: var(--danger) !important;
}

.warning {
  color: var(--warning) !important;
}

.secondary {
  color: var(--secondary) !important;
}

.light-grey-text {
  color: var(--text-light-grey);
}

.icon-size-normal {
  font-size: 1.3rem;
}

.rotate-90 {
  rotate: 90deg;
}
.rotate-180 {
  rotate: 180deg;
}
.rotate-270 {
  rotate: 270deg;
}

.text-info-description {
  color: var(--secondary);
  font-size: 0.9rem;
}
.text-info-description.small {
  font-size: 0.8rem;
}

.description p {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  color: var(--dark);
}
.description p:last-of-type {
  margin-bottom: 0;
}
.description p span.label {
  font-weight: 600;
  margin-right: 0.5rem;
  color: var(--text-light-grey);
}

.marker {
  position: absolute;
  display: block;
  background-color: var(--marker-default-c);
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  height: 86%;
  width: 6px;
}

.under-construction {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: var(--border-c);
  opacity: 0.5;
}
.under-construction i {
  font-size: 4rem;
}
.under-construction span {
  position: relative;
  color: black;
}
.under-construction span::after {
  content: "Will be added later";
  bottom: 0px;
  left: -30px;
  position: absolute;
  white-space: nowrap;
}

.justify-center {
  justify-content: center;
}

.cursor-default {
  cursor: default !important;
}

.d-none {
  display: none;
}

.overflow-all-visible {
  overflow: visible !important;
  overflow-y: visible !important;
  overflow-x: visible !important;
}

.text-nowrap {
  white-space: nowrap;
}

.t-right {
  text-align: right !important;
}

.t-left {
  text-align: left !important;
}

.t-center {
  text-align: center !important;
}

.t-nowrap {
  white-space: nowrap !important;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 4rem;
}
.page-wrapper footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

footer {
  margin-top: auto;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
}
.header-wrapper .search-with-controls {
  display: flex;
  flex-direction: row;
  column-gap: inherit;
  width: 100%;
}

.delimiter {
  position: relative;
  min-height: 37px;
  background-color: var(--site-body-bgc);
  margin: 0.9rem -0.75rem;
  padding: 0.5rem;
  box-shadow: var(--box-shadow-delimiter);
}
.delimiter[data-height-0] {
  min-height: 0;
}

.rfid-btn {
  cursor: pointer;
  width: fit-content;
  user-select: none;
  display: inline-flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  align-items: center;
  outline: none;
  text-decoration: none;
  height: 35px;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.28rem 0.6rem;
  margin: 0.2rem 0.2rem 0.2rem 0.2rem;
  line-height: 1;
  border-radius: 0.25rem;
  box-shadow: var(--box-shadow-btn);
  font-weight: 700;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.rfid-btn i {
  font-size: 1.3rem;
}
.rfid-btn:not(:disabled):focus, .rfid-btn:not(:disabled):active:focus {
  -webkit-box-shadow: var(--box-shadow-btn-focus);
  box-shadow: var(--box-shadow-btn-focus);
}
.rfid-btn:disabled {
  background-color: var(--button-disabled-bgc);
  color: var(--button-disabled-text-c);
  border-color: var(--button-disabled-border-c);
}
.rfid-btn.rfid-icon-btn {
  border: none;
  box-shadow: none !important;
}

.rfid-icon-btn {
  color: var(--icon-button-text-c);
  background-color: var(--icon-button-bgc);
}
.rfid-icon-btn.rfid-icon-btn:not(:disabled):hover {
  background-color: var(--icon-button-hover-bgc);
}

.rfid-btn-light {
  color: var(--button-light-text-c);
  background-color: var(--button-light-bgc);
  border-color: var(--button-light-border-c);
}
.rfid-btn-light:not(:disabled):hover {
  color: var(--button-light-hover-text-c);
  background-color: var(--button-light-hover-bgc);
  border-color: var(--button-light-hover-border-c);
}
.rfid-btn-light:not(:disabled):active {
  background-color: var(--button-light-active-bgc);
  border-color: var(--button-light-active-border-c);
}

.rfid-btn-primary {
  color: var(--button-primary-text-c);
  background-color: var(--button-primary-bgc);
  border-color: var(--button-primary-border-c);
}
.rfid-btn-primary:not(:disabled):hover {
  color: var(--button-primary-hover-text-c);
  background-color: var(--button-primary-hover-bgc);
  border-color: var(--button-primary-hover-border-c);
}
.rfid-btn-primary:not(:disabled):active {
  background-color: var(--button-primary-active-bgc);
  border-color: var(--button-primary-active-border-c);
}

.rfid-btn-danger {
  color: var(--button-danger-text-c);
  background-color: var(--button-danger-bgc);
  border-color: var(--button-danger-border-c);
}
.rfid-btn-danger:not(:disabled):hover {
  color: var(--button-danger-hover-text-c);
  background-color: var(--button-danger-hover-bgc);
  border-color: var(--button-danger-hover-border-c);
}
.rfid-btn-danger:not(:disabled):active {
  background-color: var(--button-danger-active-bgc);
  border-color: var(--button-danger-active-border-c);
}

.rfid-btn-list {
  display: flex;
  gap: 0.6rem;
  min-height: 35px;
  flex-wrap: nowrap;
  justify-content: flex-end;
}
.rfid-btn-list > span {
  display: inline-block;
}
.rfid-btn-list .rfid-btn {
  margin-left: 0.6rem;
}
.rfid-btn-list.list-direction-column {
  flex-direction: column;
}

.icon-list {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.action-icon,
.action-link {
  cursor: pointer;
  background: transparent;
  border: transparent;
  color: var(--primary);
  font-size: 1.125rem;
  line-height: 1;
  display: inline-flex;
  height: 100%;
  padding: 6px 6px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.15s ease;
}
.action-icon:hover,
.action-link:hover {
  color: var(--primary);
  background-color: var(--table-row-action-hover-bgc);
}

.action-link {
  font-size: 0.9rem;
}

.static-icon {
  font-size: 1.125rem;
}

.close-icon {
  line-height: 1;
  font-size: 1.125rem;
  color: var(--close-icon-c);
  cursor: pointer;
}
.close-icon:hover {
  color: var(--text-dark);
}

textarea:hover {
  border-color: var(--input-border-hover-c) !important;
}

textarea:focus-visible {
  outline: none;
}

.input-list-column {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
}

.double-control {
  display: flex;
  width: 100%;
  max-width: 600px;
  gap: 0.7rem;
}

.control-input-container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 35px;
  width: 100%;
  max-width: 300px;
  min-width: 200px;
}
.control-input-container .p-focus:focus {
  border: none;
}
.control-input-container .p-inputtext:enabled:focus {
  border-color: var(--input-border-hover-c);
}
.control-input-container .input-box {
  display: flex;
  flex: 1 1 auto;
}
.control-input-container .input-box.readonly > span {
  height: 35px;
  white-space: nowrap;
  padding: 0.4rem 0.8rem;
  border: 1px solid var(--input-readonly-border-c);
  color: var(--input-readonly-text-c);
  background-color: var(--input-readonly-bgc);
  border-left: none;
  border-radius: 0 4px 4px 0;
  width: 0;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
}
.control-input-container .input-box input[type=checkbox] {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin-left: 0.6rem;
}
.control-input-container .input-box input:not([type=checkbox]):not([type=radio]) {
  width: 0;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.control-input-container label {
  white-space: nowrap;
  flex-shrink: 0;
  line-height: 1.7;
  font-weight: 600;
  color: var(--secondary);
  background-color: var(--input-label-bgc);
  padding: 0.28rem 0.8rem;
  height: 100%;
  border: 1px solid var(--input-border-c);
  border-radius: 4px 0 0 4px;
}
.control-input-container label::after {
  content: ":";
}
.control-input-container.textarea {
  height: auto;
}
.control-input-container.textarea textarea {
  width: 100%;
}

.required label::after {
  content: "" !important;
}
.required span.required-marker::after {
  content: "*";
  color: var(--input-required-marker-c);
}
.required span.required-marker::before {
  content: ":";
}

.input-component-wrapper {
  position: relative;
  width: 100%;
  max-width: 300px;
  min-width: 200px;
}
.input-component-wrapper .control-input-container {
  max-width: inherit;
  min-width: inherit;
}

.control-input-counter {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
}
.control-input-counter .p-inputnumber-input {
  max-width: 65px;
}

.control-input-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
}
.control-input-checkbox p-checkbox {
  display: contents;
}
.control-input-checkbox label {
  white-space: nowrap;
  padding-left: 27px;
  line-height: 1.4;
}
.control-input-checkbox.disabled label {
  color: var(--text-offline-state);
}
.control-input-checkbox .p-checkbox,
.control-input-checkbox .p-radiobutton {
  position: absolute;
}
.control-input-checkbox .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
  border-color: var(--input-border-hover-c);
}
.control-input-checkbox i.mdi-close {
  margin-left: 0.2rem;
}

.p-radiobutton-checked .p-radiobutton-box {
  border-color: var(--checkbox-checked-border-c);
  background: var(--checkbox-checked-bgc);
}

.p-checkbox-checked .p-checkbox-box {
  border-color: var(--checkbox-checked-border-c);
  background: var(--checkbox-checked-bgc);
}

.checkbox-list {
  display: flex;
  gap: 1.5rem;
}

.control-input:not([type=checkbox]) {
  min-height: 35px;
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0 4px 4px 0;
  border: 1px solid var(--input-border-c);
  border-left: none;
  transition: border-color 0.2s ease;
}
.control-input:not([type=checkbox]):not([disabled]):hover {
  border-color: var(--input-border-hover-c);
}
.control-input:not([type=checkbox]).without-label {
  border-left: 1px solid var(--input-border-c);
  border-radius: 4px;
}

.p-select,
.p-multiselect {
  border: 1px solid var(--input-border-c);
  border-left: none;
  border-radius: 0 4px 4px 0;
  height: 35px;
  width: 0;
  flex: 1 1 auto;
  align-items: center;
}
.p-select.p-disabled,
.p-multiselect.p-disabled {
  background: var(--input-readonly-bgc);
}
.p-select.p-disabled span,
.p-multiselect.p-disabled span {
  line-height: 1;
  color: var(--input-readonly-text-c);
  background-color: var(--input-readonly-bgc);
}
.p-select .p-inputtext,
.p-select input,
.p-multiselect .p-inputtext,
.p-multiselect input {
  font: inherit !important;
}
.p-select:not(:disabled):hover,
.p-multiselect:not(:disabled):hover {
  border-color: var(--input-border-hover-c);
}
.p-select .p-multiselect-filter-container,
.p-multiselect .p-multiselect-filter-container {
  display: flex;
}

.p-autocomplete {
  height: 35px;
  width: 0;
  flex: 1 1 auto;
  align-items: center;
}
.p-autocomplete .p-autocomplete-input {
  border-radius: 0 4px 4px 0;
  padding-right: 2.2rem;
  border-left: 0;
  height: inherit;
}
.p-autocomplete .p-autocomplete-multiple-container {
  width: 100%;
}
.p-autocomplete.p-autocomplete-clearable .p-inputtext {
  padding-right: 3rem;
}

.p-inputtext:enabled:hover {
  border-color: var(--input-border-hover-c);
}

.p-autocomplete-dropdown {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.p-autocomplete-dropdown,
.p-multiselect-dropdown,
.p-select-dropdown {
  position: absolute;
  right: 8px;
  width: auto;
  color: var(--internal-button-c);
}
.p-autocomplete-dropdown:hover,
.p-multiselect-dropdown:hover,
.p-select-dropdown:hover {
  color: var(--internal-button-hover-c);
  background-color: var(--internal-button-bgc);
}
.p-autocomplete-dropdown:not(:disabled):active, .p-autocomplete-dropdown:not(:disabled):focus,
.p-multiselect-dropdown:not(:disabled):active,
.p-multiselect-dropdown:not(:disabled):focus,
.p-select-dropdown:not(:disabled):active,
.p-select-dropdown:not(:disabled):focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.p-autocomplete-dropdown .p-icon,
.p-multiselect-dropdown .p-icon,
.p-select-dropdown .p-icon {
  height: 14px;
  width: 14px;
}

.p-multiselect-clear-icon,
.p-autocomplete-clear-icon {
  position: absolute;
  right: 27px;
  height: 14px;
  width: 14px;
  color: var(--internal-button-c);
}
.p-multiselect-clear-icon:hover,
.p-autocomplete-clear-icon:hover {
  color: var(--internal-button-hover-c);
  background-color: var(--internal-button-bgc);
}
.p-multiselect-clear-icon:not(:disabled):active, .p-multiselect-clear-icon:not(:disabled):focus,
.p-autocomplete-clear-icon:not(:disabled):active,
.p-autocomplete-clear-icon:not(:disabled):focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.p-select-overlay .p-select-list .p-select-option {
  color: var(--input-dropdown-option-text-c);
}
.p-select-overlay .p-select-list .p-select-option:not(.p-disabled):hover {
  background: var(--input-dropdown-option-hover-bgc);
}
.p-select-overlay .p-select-list .p-select-option:not(.p-disabled).p-select-option-selected:hover {
  background: var(--item-keyboard-select-hover-bgc);
}
.p-select-overlay .p-select-list .p-select-option.p-select-option-selected {
  background: var(--item-keyboard-select-bgc);
}
.p-select-overlay .p-select-list .p-select-option.p-select-option-selected.p-focus {
  background: var(--item-keyboard-select-hover-bgc);
}

.p-autocomplete-overlay {
  width: 0;
  flex-grow: 1;
}
.p-autocomplete-overlay .p-autocomplete-list .p-autocomplete-option-group {
  font-weight: 700;
}
.p-autocomplete-overlay .p-autocomplete-list .p-autocomplete-option {
  color: var(--input-dropdown-option-text-c);
}
.p-autocomplete-overlay .p-autocomplete-list .p-autocomplete-option:not(.p-disabled):hover {
  background: var(--input-dropdown-option-hover-bgc);
}
.p-autocomplete-overlay .p-autocomplete-list .p-autocomplete-option:not(.p-disabled).p-autocomplete-option-selected:hover {
  background: var(--item-keyboard-select-hover-bgc);
}
.p-autocomplete-overlay .p-autocomplete-list .p-autocomplete-option.p-autocomplete-option-selected {
  background: var(--item-keyboard-select-bgc);
}
.p-autocomplete-overlay .p-autocomplete-list .p-autocomplete-option.p-autocomplete-option-selected.p-focus {
  background: var(--item-keyboard-select-hover-bgc);
}
.p-autocomplete-overlay .p-autocomplete-list .p-autocomplete-option span {
  word-break: break-word;
  white-space: normal;
}

.p-multiselect-overlay .p-multiselect-header .p-multiselect-filter-container p-iconfield {
  display: flex;
  flex: 1 1 auto;
}
.p-multiselect-overlay .p-multiselect-option-list .p-multiselect-option {
  color: var(--input-dropdown-option-text-c);
}
.p-multiselect-overlay .p-multiselect-option-list .p-multiselect-option:not(.p-disabled):hover {
  background: var(--input-dropdown-option-hover-bgc);
}
.p-multiselect-overlay .p-multiselect-option-list .p-multiselect-option:not(.p-disabled).p-multiselect-option-selected:hover {
  background: var(--item-keyboard-select-hover-bgc);
}
.p-multiselect-overlay .p-multiselect-option-list .p-multiselect-option.p-multiselect-option-selected {
  background: var(--item-keyboard-select-bgc);
}
.p-multiselect-overlay .p-multiselect-option-list .p-multiselect-option.p-multiselect-option-selected.p-focus {
  background: var(--item-keyboard-select-hover-bgc);
}

.p-select-overlay,
.p-autocomplete-overlay,
.p-multiselect-overlay {
  border-radius: 4px;
}

.without-label .input-box > input,
.without-label .input-box > textarea,
.label-top .input-box > input,
.label-top .input-box > textarea {
  border-left: 1px solid var(--input-border-c);
  border-radius: 4px;
}
.without-label .input-box > textarea,
.label-top .input-box > textarea {
  border-left: 1px solid var(--input-border-c);
  border-radius: 4px;
}
.without-label .input-box.readonly span,
.label-top .input-box.readonly span {
  border-left: 1px solid var(--input-border-c);
  border-radius: 4px;
}
.without-label .p-select,
.without-label .p-autocomplete,
.label-top .p-select,
.label-top .p-autocomplete {
  border-radius: 4px;
}
.without-label .p-select,
.label-top .p-select {
  border-left: 1px solid var(--input-border-c);
}
.without-label .p-select:hover,
.label-top .p-select:hover {
  border-color: var(--input-border-hover-c);
}
.without-label .p-autocomplete .p-autocomplete-input,
.label-top .p-autocomplete .p-autocomplete-input {
  border-left: 1px solid var(--input-border-c);
  border-radius: 4px;
}
.without-label .p-autocomplete .p-autocomplete-input:hover,
.label-top .p-autocomplete .p-autocomplete-input:hover {
  border-color: var(--input-border-hover-c);
}
.without-label .p-multiselect,
.label-top .p-multiselect {
  border-left: 1px solid var(--input-border-c);
  border-radius: 4px;
}
.without-label .p-multiselect:not(.p-disabled):hover,
.label-top .p-multiselect:not(.p-disabled):hover {
  border-color: var(--input-border-hover-c);
}
.without-label .skeleton,
.label-top .skeleton {
  width: 100%;
  border-radius: 4px !important;
  border-left: 1px solid var(--input-border-c) !important;
}

.label-top .control-input-container, .label-top.control-input-container {
  margin-top: 1.3rem;
}
.label-top label {
  height: auto;
  position: absolute;
  top: -21px;
  left: 0;
  line-height: 1;
  padding: 0;
  background: inherit;
  border: 0;
  color: var(--input-label-text-c);
}

.switcher-container {
  display: flex;
  flex-wrap: nowrap;
  height: fit-content;
  column-gap: 0.7rem;
}
.switcher-container label {
  position: relative;
  max-width: 180px;
}
.switcher-container .p-toggleswitch {
  width: 2.5rem;
  height: 1.25rem;
  vertical-align: text-top;
}
.switcher-container .p-toggleswitch.p-toggleswitch-checked .p-toggleswitch-slider {
  background: var(--toggle-switch-checked-bgc);
  border-color: var(--toggle-switch-checked-border-c);
}

.p-overlay .p-overlay-content {
  display: flex;
}

.p-popover {
  border: 1px solid var(--border-c);
  box-shadow: var(--box-shadow-overlay-p);
}
.p-popover::before {
  border-bottom-color: var(--border-c);
}
.p-popover .p-popover-content {
  width: 400px;
}
.p-popover.custom-position {
  margin-left: 0;
  margin-bottom: 10px;
}
.p-popover.custom-position::before {
  top: -8px;
  right: 25px;
  left: auto;
  rotate: 45deg;
  bottom: auto;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  border-color: #c9ceda transparent transparent #c9ceda;
  background-color: #fff;
}
.p-popover.custom-position::after {
  top: 8px;
  right: 23px;
  rotate: 180deg;
  bottom: auto;
  border-color: transparent;
}

.overlay-custom-position-wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: 0;
  flex: 1 1 auto;
}
.overlay-custom-position-wrapper .overlay-target {
  height: 0;
  position: absolute;
  right: 0;
  top: 100%;
}

.add-input {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 0.7rem;
}

.input-icon-absolute-right {
  position: relative;
}
.input-icon-absolute-right i {
  line-height: 1;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translate(0, -50%);
}
.input-icon-absolute-right input.control-input {
  padding-right: 2rem;
}

.rfid-card ._description {
  margin-bottom: 0.5rem;
  color: var(--secondary);
  font-size: 0.8rem;
}
.rfid-card .card-editable-field {
  min-width: 130px;
  max-width: 130px;
}
.rfid-card .group-label {
  font-size: 1rem;
  color: var(--text-light-grey);
  font-weight: 600;
}

.custom-confirm-popup {
  width: 450px;
}
.custom-confirm-popup::after {
  display: none;
  margin-left: -13px;
}
.custom-confirm-popup::before {
  display: none;
  margin-left: -15px;
}
.custom-confirm-popup .p-confirm-popup-content {
  padding: 0;
}
.custom-confirm-popup .confirm-popup-content {
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  padding: 1.5rem 1rem 0.5rem;
  width: 100%;
}
.custom-confirm-popup .confirm-popup-content .confirm-popup-icon {
  text-align: center;
  line-height: 1;
  font-size: 3rem;
  color: var(--warning);
}
.custom-confirm-popup .confirm-popup-content .confirm-popup-message-text {
  text-align: center;
}
.custom-confirm-popup .confirm-popup-content .confirm-popup-header-text {
  text-align: center;
  font-weight: 600;
  font-size: 1.125rem;
}
.custom-confirm-popup .p-confirmpopup-footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  column-gap: 2rem;
  padding: 0.5rem 1rem 1.5rem;
}
.custom-confirm-popup .p-confirmpopup-footer button {
  cursor: pointer;
  white-space: nowrap;
  flex-wrap: nowrap;
  outline: none;
  text-decoration: none;
  height: 35px;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.28rem 0.6rem;
  margin: 0.2rem 0.2rem 0.2rem 0.2rem;
  line-height: 1;
  border-radius: 0.25rem;
  box-shadow: var(--box-shadow-btn);
  font-weight: 700;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.custom-confirm-popup .p-confirmpopup-footer .p-confirmpopup-reject-button button {
  color: var(--button-light-text-c);
  background-color: var(--button-light-bgc);
  border-color: var(--button-light-border-c);
}
.custom-confirm-popup .p-confirmpopup-footer .p-confirmpopup-reject-button button:not(:disabled):hover {
  color: var(--button-light-hover-text-c);
  background-color: var(--button-light-hover-bgc);
  border-color: var(--button-light-hover-border-c);
}
.custom-confirm-popup .p-confirmpopup-footer .p-confirmpopup-reject-button button:not(:disabled):active {
  background-color: var(--button-light-active-bgc);
  border-color: var(--button-light-active-border-c);
}
.custom-confirm-popup .p-confirmpopup-footer .p-confirmpopup-accept-button button {
  background-color: var(--button-primary-bgc);
  border-color: var(--button-primary-border-c);
}
.custom-confirm-popup .p-confirmpopup-footer .p-confirmpopup-accept-button button .p-button-label {
  color: var(--button-primary-text-c);
}
.custom-confirm-popup .p-confirmpopup-footer .p-confirmpopup-accept-button button:not(:disabled):hover {
  background-color: var(--button-primary-hover-bgc);
  border-color: var(--button-primary-hover-border-c);
}
.custom-confirm-popup .p-confirmpopup-footer .p-confirmpopup-accept-button button:not(:disabled):hover .p-button-label {
  color: var(--button-primary-hover-text-c);
}
.custom-confirm-popup .p-confirmpopup-footer .p-confirmpopup-accept-button button:not(:disabled):active {
  background-color: var(--button-primary-active-bgc);
  border-color: var(--button-primary-active-border-c);
}

.p-fileupload {
  display: flex;
  flex-wrap: nowrap;
  width: 400px;
}
.p-fileupload.p-fileupload-advanced {
  border: none;
  position: relative;
}
.p-fileupload .p-fileupload-header {
  padding: 0;
}
.p-fileupload .p-fileupload-header p-button {
  display: contents;
}
.p-fileupload .p-fileupload-buttonbar {
  display: flex;
  justify-content: center;
  padding: 0;
  border-right: 0;
  border-radius: 4px 0 0 4px;
}
.p-fileupload .p-fileupload-choose-button {
  color: var(--button-primary-text-c);
  background: var(--button-primary-bgc);
  border-color: var(--button-primary-border-c);
  margin: 0;
  border-right: 0;
  border-radius: 4px 0 0 4px;
  height: 100%;
  padding: 0.5rem 1rem;
}
.p-fileupload .p-fileupload-choose-button .p-iconwrapper {
  color: var(--button-primary-text-c);
}
.p-fileupload .p-fileupload-choose-button:not(:disabled):hover {
  color: var(--button-primary-hover-text-c);
  background-color: var(--button-primary-hover-bgc);
  border-color: var(--button-primary-hover-border-c);
}
.p-fileupload .p-fileupload-choose-button:not(:disabled):active {
  background-color: var(--button-primary-active-bgc);
  border-color: var(--button-primary-active-border-c);
}
.p-fileupload p-message,
.p-fileupload p-progressbar {
  display: contents;
}
.p-fileupload .p-fileupload-content {
  border-left: 0;
  border-radius: 0 4px 4px 0;
  padding: 0.7rem;
  padding-right: 1.8rem;
  width: 1%;
  flex-grow: 1;
}
.p-fileupload .p-fileupload-content .p-progressbar {
  display: none !important;
}
.p-fileupload .p-fileupload-content .p-fileupload-file-list .file-name-wrapper .file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-fileupload .p-fileupload-content .p-fileupload-file-list .file-name-wrapper i {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 1.125rem;
  cursor: pointer;
  color: var(--text-light-grey);
}
.p-fileupload .p-fileupload-content .p-fileupload-file-list .file-name-wrapper i:hover {
  color: var(--dark);
}
.p-fileupload .p-fileupload-content .p-message {
  position: absolute;
  top: 55px;
  left: 0;
  max-width: 400px;
  margin-bottom: 0;
}
.p-fileupload .p-fileupload-content .p-message .p-message-wrapper {
  transition: 0s;
  flex-direction: column;
  padding: 1rem 1rem 1rem 3rem;
  align-items: flex-start;
}
.p-fileupload .p-fileupload-content .p-message .p-message-wrapper .p-message-detail {
  margin-left: 0;
}

.table-wrapper {
  height: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.table-error .p-inline-message-text {
  white-space: nowrap !important;
}

p-table {
  display: contents;
}

.p-datatable {
  height: 100%;
}
.p-datatable .p-datatable-table-container {
  height: 100%;
  overflow: auto;
}
.p-datatable .p-datatable-table-container .p-datatable-thead {
  position: sticky;
  top: 0;
  white-space: nowrap;
  z-index: 12;
}
.p-datatable .p-datatable-table-container .p-datatable-thead .p-iconwrapper:has(.p-sortable-column-icon) {
  display: none;
}
.p-datatable .p-datatable-table-container .p-datatable-thead tr th {
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  vertical-align: middle;
  color: var(--table-header-text-c);
  background-color: var(--table-header-bgc);
}
.p-datatable .p-datatable-table-container .p-datatable-thead tr th.icon-only i {
  padding: 0 3px;
}
.p-datatable .p-datatable-table-container .p-datatable-thead tr th.p-sortable-column .p-sortable-column-icon {
  margin-bottom: -0.2rem;
  margin-left: 0;
}
.p-datatable .p-datatable-table-container .p-datatable-thead tr th.p-sortable-column .p-sortable-column-icon i {
  line-height: 1;
}
.p-datatable .p-datatable-table-container .p-datatable-thead tr th.clm-Image {
  width: 0%;
  min-width: 60px;
}
.p-datatable .p-datatable-table-container .p-datatable-thead tr:nth-child(2) th {
  background-color: var(--table-second-header-bgc);
  border: none;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr {
  height: 45px;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td {
  color: var(--table-row-text-c);
  border-bottom: 1px solid var(--table-row-border-c);
  vertical-align: middle;
  padding: 0.4rem 0.6rem;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td div.table-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--table-row-img-box-border-c);
  border-radius: 4px;
  background: var(--light);
  overflow: hidden;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td div.table-img-box img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td div.table-img-box i {
  font-size: 1.5rem;
  display: flex;
  height: 100%;
  align-items: center;
  color: var(--table-row-img-box-icon-c);
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td.row-action-wrapper .row-action {
  cursor: pointer;
  color: var(--table-row-action-text-c);
  font-size: 1.125rem;
  line-height: 1;
  display: inline-flex;
  height: 100%;
  padding: 6px 6px;
  align-items: center;
  justify-content: center;
  border-radius: 25%;
  transition: background-color 0.15s ease;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td.row-action-wrapper .row-action.--text-fz {
  font-size: 0.9rem;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td.row-action-wrapper .row-action:hover {
  color: var(--table-row-action-hover-text-c);
  background-color: var(--table-row-action-hover-bgc);
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td.row-action-wrapper .row-action.active {
  background-color: var(--table-row-action-selected-bgc);
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td.row-action-wrapper .row-action:nth-child(n+2) {
  margin-left: 0.2rem;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td.row-action-wrapper .expand-chevron {
  line-height: 0.8;
  font-size: 1.4rem;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr td.row-action-wrapper .static-icon {
  padding: 0 6px;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr:not(.expanded-row):hover {
  background-color: var(--table-row-hover-bgc);
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr:not(.expanded-row):hover td {
  color: var(--table-row-hover-text-c);
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr:not(.expanded-row):hover .row-action-wrapper .row-action {
  color: var(--table-row-action-hover-text-c);
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr.expanded-row {
  box-shadow: 0px 9px 5px -5px rgba(56, 88, 166, 0.1215686275) inset, 0px -8px 5px -5px rgba(56, 88, 166, 0.1215686275) inset;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr.expanded-row > td {
  padding: 0;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr.expanded-row .expanded-row-content {
  padding: 0.5rem 0;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr.no-hover:hover {
  background-color: transparent;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr.row-selected {
  -webkit-box-shadow: var(--item-selected-box-shadow);
  -moz-box-shadow: var(--item-selected-box-shadow);
  box-shadow: var(--item-selected-box-shadow);
  background: var(--item-selected-bgc) !important;
  color: inherit !important;
  position: relative;
  z-index: 10;
}
.p-datatable .p-datatable-table-container .p-datatable-tbody tr:last-of-type td {
  border-bottom: none;
}
.p-datatable .p-datatable-table-container i {
  font-size: 1.125rem;
  display: inline-block;
}
.p-datatable.row-select .p-datatable-tbody tr:not(.expanded-row) {
  cursor: pointer;
}

.table-header-selection,
.table-row-selection {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 0.5rem;
}
.table-header-selection p-tableheadercheckbox,
.table-header-selection p-tableCheckbox,
.table-row-selection p-tableheadercheckbox,
.table-row-selection p-tableCheckbox {
  display: contents;
}
.table-header-selection label,
.table-row-selection label {
  line-height: 1;
}

.p-tooltip {
  text-align: center;
  padding: 0;
}
.p-tooltip .p-tooltip-arrow {
  scale: 1;
  position: absolute;
  width: 0.482rem;
  height: 0.482rem;
  rotate: 45deg;
  background: var(--tooltip-bgc);
  border: none;
}
.p-tooltip .p-tooltip-arrow::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
  border: 1px solid transparent;
}
.p-tooltip.p-tooltip-right {
  padding-left: 0.34rem;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  top: 50%;
  left: 2px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow::after {
  border-color: transparent transparent var(--tooltip-border-c) var(--tooltip-border-c);
}
.p-tooltip.p-tooltip-left {
  padding-right: 0.34rem;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  top: 50%;
  right: 2px;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow::after {
  border-color: var(--tooltip-border-c) var(--tooltip-border-c) transparent transparent;
}
.p-tooltip.p-tooltip-top {
  padding-bottom: 0.34rem;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  bottom: 2px;
  left: 50%;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow::after {
  border-color: transparent var(--tooltip-border-c) var(--tooltip-border-c) transparent;
}
.p-tooltip.p-tooltip-bottom {
  padding-top: 0.34rem;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  top: 2px;
  left: 50%;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow::after {
  border-color: var(--tooltip-border-c) transparent transparent var(--tooltip-border-c);
}
.p-tooltip .p-tooltip-text {
  border: 1px solid var(--tooltip-border-c);
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  box-shadow: none;
  background-color: var(--tooltip-bgc);
  color: var(--tooltip-text-c);
}

.sidebar-container,
.p-drawer {
  box-shadow: var(--box-shadow-sidebar);
}
.sidebar-container .p-drawer-header,
.p-drawer .p-drawer-header {
  padding: 1rem;
  background-color: var(--light);
  justify-content: space-between;
}
.sidebar-container .p-drawer-content,
.p-drawer .p-drawer-content {
  display: flex;
  flex: 1 1 auto;
  position: static;
  flex-direction: column;
  padding-top: 1rem;
}
.sidebar-container .p-drawer-content .filters-container,
.p-drawer .p-drawer-content .filters-container {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.sidebar-container .closeButton .p-button,
.p-drawer .closeButton .p-button {
  width: 30px;
  height: 30px;
  background-color: var(--internal-button-bgc);
  color: var(--internal-button-c);
}
.sidebar-container .closeButton .p-button:hover,
.p-drawer .closeButton .p-button:hover {
  color: var(--internal-button-hover-c);
}
.sidebar-container .sidebar-button.bottom-center,
.p-drawer .sidebar-button.bottom-center {
  margin-top: auto;
  text-align: center;
}

.p-dialog {
  border-radius: 4px;
}
.p-dialog .p-dialog-content {
  padding: 0.75rem;
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid var(--border-c);
  padding: 0.9rem;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1.5rem;
}
.p-dialog .p-dialog-header .p-dialog-close-button {
  width: 30px;
  height: 30px;
  background-color: var(--internal-button-bgc);
  color: var(--internal-button-c);
}
.p-dialog .p-dialog-header .p-dialog-close-button:hover {
  color: var(--internal-button-hover-c);
}
.p-dialog .p-dialog-footer {
  padding: 0.75rem;
  padding-top: 0;
}
.p-dialog .p-dialog-footer button {
  margin-right: 0;
}

.badge-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  width: 100%;
}

.badge {
  font-weight: 600;
  padding: 0.35rem 0.6rem;
  line-height: 1;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 4px;
  white-space: nowrap;
  height: 1.5rem;
  color: var(--text-secondary);
}
.badge.corner-badge {
  position: absolute;
  padding: 0.35rem;
  border-radius: 0;
}
.badge.corner-badge.top-left {
  left: 0;
  top: 0;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 4px;
}
.badge.badge-grey {
  color: var(--badge-grey-text-c);
  background-color: var(--badge-grey-bgc);
}
.badge.badge-warning {
  color: var(--badge-warning-text-c);
  background-color: var(--badge-warning-bgc);
}
.badge.badge-info {
  color: var(--badge-info-text-c);
  background-color: var(--badge-info-bgc);
}
.badge.badge-dark-blue {
  color: var(--badge-dark-blue-text-c);
  background-color: var(--badge-dark-blue-bgc);
}
.badge.badge-danger {
  color: var(--badge-danger-text-c);
  background-color: var(--badge-danger-bgc);
}
.badge.badge-primary {
  color: var(--badge-primary-text-c);
  background-color: var(--badge-primary-bgc);
}
.badge.badge-sld-layout {
  color: var(--badge-sld-layout-text-c);
  background-color: var(--badge-sld-layout-bgc);
}
.badge.badge-zpl-layout {
  color: var(--badge-zpl-layout-text-c);
  background-color: var(--badge-zpl-layout-bgc);
}

.tabs-wrapper {
  margin-bottom: 1rem;
  position: relative;
}

.p-tablist .p-tablist-tab-list {
  border-color: var(--tab-menu-border-c);
}
.p-tablist .p-tablist-tab-list .p-tab {
  padding: 0.7rem;
  font-size: 1rem;
  color: var(--tab-menu-text-c);
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-bottom-color: var(--tab-menu-border-c);
  box-shadow: none;
  transition: none;
  background-color: var(--tab-menu-link-bgc);
}
.p-tablist .p-tablist-tab-list .p-tab.p-tab-active {
  color: var(--tab-menu-text-c);
  border-width: 1px;
  border-color: var(--tab-menu-border-c);
  border-bottom-color: transparent;
  background-color: var(--tab-menu-link-active-bgc);
  border-radius: 4px 4px 0 0;
}
.p-tablist .p-tablist-tab-list .p-tablist-active-bar {
  background-color: transparent;
  border-color: transparent;
}

.has-error-message .p-message {
  display: none;
  position: absolute;
  z-index: 1000000;
}
.has-error-message .pos-bottom .p-message {
  left: 0;
  top: calc(100% + 5px);
}
.has-error-message .pos-top .p-message {
  left: 0;
  bottom: calc(100% + 5px);
}
.has-error-message .pos-left .p-message {
  top: 50%;
  transform: translate(0, -50%);
  right: calc(100% + 10px);
}
.has-error-message .pos-right .p-message {
  top: 50%;
  transform: translate(0, -50%);
  left: calc(100% + 10px);
}
.has-error-message:hover .p-message {
  display: flex;
  width: max-content;
  max-width: 300px;
}
.has-error-message label {
  border-color: var(--danger) var(--border-c) var(--danger) var(--danger) !important;
}
.has-error-message input,
.has-error-message textarea {
  border-color: var(--danger) !important;
}
.has-error-message .input-box > span {
  height: 35px;
  line-height: 1.5;
  border-color: var(--danger) !important;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
}

.p-message {
  margin: 0;
  outline: none;
}
.p-message .p-message-content {
  padding: 0.5rem;
  position: relative;
}
.p-message .p-message-text {
  word-break: break-word;
  white-space: normal;
  line-height: 1.4;
  font-size: 0.8rem;
  font-weight: 400;
}
.p-message .p-message-icon {
  font-size: 1.125rem;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -35%);
  left: 12px;
}
.p-message.p-message-error {
  color: var(--message-detail-text-c);
  background-color: var(--message-error-bgc);
  border-radius: 4px;
  border-left: 3px solid var(--message-error-border-c);
}
.p-message.p-message-error .p-message-icon {
  color: var(--message-error-icon-c);
}
.p-message.p-message-success {
  margin: 0;
  color: var(--message-detail-text-c);
  background-color: var(--message-success-bgc);
  border-radius: 4px;
  border-left: 3px solid var(--message-success-border-c);
}
.p-message.p-message-success .p-message-icon {
  color: var(--message-success-icon-c);
}
.p-message .p-message-close {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 8px;
}
.p-message .p-message-close .p-icon {
  color: var(--message-close-icon-c);
  width: 1rem;
  height: 1rem;
}

.p-toast .p-toast-summary {
  color: var(--message-summary-text-c);
}
.p-toast .p-toast-detail {
  color: var(--message-detail-text-c);
}
.p-toast .p-toast-message-icon {
  display: flex;
}
.p-toast .p-toast-icon-close .p-icon {
  color: var(--message-close-icon-c);
}
.p-toast .p-toast-message-error {
  background: var(--message-error-bgc);
  border: solid var(--message-error-border-c);
  border-width: 0 0 0 6px;
}
.p-toast .p-toast-message-error .p-toast-message-icon {
  font-size: 1.5rem;
  color: var(--message-error-icon-c);
}
.p-toast .p-toast-message-success {
  background: var(--message-success-bgc);
  border: solid var(--message-success-border-c);
  border-width: 0 0 0 6px;
}
.p-toast .p-toast-message-success .p-toast-message-icon {
  font-size: 1.5rem;
  color: var(--message-success-icon-c);
}
.p-toast .p-toast-message-warn {
  background: var(--message-warning-bgc);
  border: solid var(--message-warning-border-c);
  border-width: 0 0 0 6px;
}
.p-toast .p-toast-message-warn .p-toast-message-icon {
  font-size: 1.5rem;
  color: var(--message-warning-icon-c);
}
.p-toast .p-toast-message-content {
  padding: 0.7rem;
  align-items: center;
}
.p-toast .p-toast-message-content .p-toast-close-button {
  background-color: var(--internal-button-bgc);
  color: var(--internal-button-c);
}
.p-toast .p-toast-message-content .p-toast-close-button:hover {
  color: var(--internal-button-hover-c);
}
.p-toast.header-only .p-toast-detail {
  display: none;
}

.p-orderlist .p-orderlist-list-container {
  border: 0;
  border-radius: 4px;
}
.p-orderlist .p-orderlist-list {
  border: 0;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  pointer-events: none;
  overflow: visible;
}
.p-orderlist .p-orderlist-list .p-orderlist-item p-checkbox {
  pointer-events: all;
}
.p-orderlist .p-orderlist-list .p-orderlist-item span.p-ink {
  display: none;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: inherit;
  background: transparent;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:hover {
  background: #fff;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
  background: #fff;
}
.p-orderlist .p-orderlist-controls {
  display: none;
}

.cdk-drag {
  overflow: visible;
  position: relative;
  padding: 0.7rem;
  margin-left: 45px;
}
.cdk-drag.cdk-drag-preview {
  box-shadow: var(--item-selected-box-shadow);
}
.cdk-drag .drag-marker {
  pointer-events: all;
  cursor: grab;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -35px;
}

.p-accordion .p-icon-wrapper .p-icon {
  width: 0.8rem;
  height: 0.8rem;
}
.p-accordion .p-accordionpanel {
  border-bottom: 1px solid var(--border-c);
}
.p-accordion .p-accordionpanel-active .p-accordionheader {
  background-color: var(--item-selected-bgc);
}
.p-accordion .p-accordionheader {
  padding: 0.7rem;
  color: var(--text-secondary);
  background-color: var(--white);
}
.p-accordion .p-accordionheader:hover {
  background-color: var(--line-hover-bgc);
  color: var(--secondary);
}
.p-accordion .p-accordion-content {
  padding: 0.5rem;
}
.p-accordion .p-accordion-content ul {
  padding: 0.3rem;
}
.p-accordion .p-accordion-content ul li {
  white-space: nowrap;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  height: 35px;
}
.p-accordion .p-accordion-content i {
  padding: 0 5px;
  vertical-align: middle;
}

.ngx-datatable {
  display: block;
  overflow: hidden;
  justify-content: center;
  position: relative;
  transform: translate3d(0, 0, 0);
  /**
   * Vertical Scrolling Adjustments
   */
  /**
   * Horizontal Scrolling Adjustments
   */
  /**
   * Fixed Header Height Adjustments
   */
  /**
   * Fixed row height adjustments
   */
  /**
   * Shared Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */
}
.ngx-datatable [hidden] {
  display: none !important;
}
.ngx-datatable *,
.ngx-datatable *:before,
.ngx-datatable *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ngx-datatable.scroll-vertical .datatable-body {
  overflow-y: auto;
}
.ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
  position: absolute;
}
.ngx-datatable.scroll-horz .datatable-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
  white-space: nowrap;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable .datatable-body-row,
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-header-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  -webkit-flex-flow: row;
  -moz-flex-flow: row;
  -ms-flex-flow: row;
  -o-flex-flow: row;
  flex-flow: row;
}
.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  overflow-x: hidden;
  vertical-align: top;
  display: inline-block;
  line-height: 1.625;
}
.ngx-datatable .datatable-body-cell:focus,
.ngx-datatable .datatable-header-cell:focus {
  outline: none;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-right {
  z-index: 9;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-row-group,
.ngx-datatable .datatable-row-right {
  position: relative;
}
.ngx-datatable .datatable-header {
  display: block;
  overflow: hidden;
}
.ngx-datatable .datatable-header .datatable-header-inner {
  align-items: stretch;
  -webkit-align-items: stretch;
}
.ngx-datatable .datatable-header .datatable-header-cell {
  position: relative;
  display: inline-block;
}
.ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
  cursor: move;
}
.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  line-height: 100%;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  padding: 0 4px;
  visibility: hidden;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
  cursor: ew-resize;
}
.ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
  position: absolute;
  top: 0;
  bottom: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
  right: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
  left: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  height: inherit;
}
.ngx-datatable .datatable-body {
  position: relative;
  z-index: 10;
  display: block;
}
.ngx-datatable .datatable-body .datatable-scroll {
  display: inline-block;
}
.ngx-datatable .datatable-body .datatable-row-detail {
  overflow-y: hidden;
}
.ngx-datatable .datatable-body .datatable-row-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ngx-datatable .datatable-body .datatable-body-row {
  outline: none;
}
.ngx-datatable .datatable-body .datatable-body-row > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.ngx-datatable .datatable-footer {
  display: block;
  width: 100%;
  overflow: auto;
}
.ngx-datatable .datatable-footer .datatable-footer-inner {
  display: flex;
  align-items: center;
  width: 100%;
}
.ngx-datatable .datatable-footer .selected-count .page-count {
  flex: 1 1 40%;
}
.ngx-datatable .datatable-footer .selected-count .datatable-pager {
  flex: 1 1 60%;
}
.ngx-datatable .datatable-footer .page-count {
  flex: 1 1 20%;
}
.ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 80%;
  text-align: right;
}
.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li,
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  outline: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  cursor: pointer;
  display: inline-block;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  cursor: not-allowed;
}

@font-face {
  font-family: "data-table";
  src: url("fonts/data-table.eot");
  src: url("fonts/data-table.eot?#iefix") format("embedded-opentype"), url("fonts/data-table.woff") format("woff"), url("fonts/data-table.ttf") format("truetype"), url("fonts/data-table.svg#data-table") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]::before {
  font-family: "data-table" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=datatable-icon-]::before,
[class*=" datatable-icon-"]::before {
  font-family: "data-table" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datatable-icon-filter::before {
  content: "b";
}

.datatable-icon-collapse::before {
  content: "a";
}

.datatable-icon-expand::before {
  content: "c";
}

.datatable-icon-close::before {
  content: "d";
}

.datatable-icon-up::before {
  content: "e";
}

.datatable-icon-down::before {
  content: "f";
}

.datatable-icon-sort-unset::before {
  content: "c";
  opacity: 0.5;
}

.datatable-icon-sort::before {
  content: "g";
}

.datatable-icon-done::before {
  content: "h";
}

.datatable-icon-done-all::before {
  content: "i";
}

.datatable-icon-search::before {
  content: "j";
}

.datatable-icon-pin::before {
  content: "k";
}

.datatable-icon-add::before {
  content: "m";
}

.datatable-icon-left::before {
  content: "o";
}

.datatable-icon-right::before {
  content: "p";
}

.datatable-icon-skip::before {
  content: "q";
}

.datatable-icon-prev::before {
  content: "r";
}

/*
  This stylesheet uses scss valiables for most of the colors / background-colors of the table
  to enable the customization of the displayed table without cloning the stylesheet into the
  own application.

  To modify table colors, add the following lines to the scss file of your application
  (this example modifies the color of the selected row - selectionType = single, multi or multiClick):

  $ngx-datatable-selected-active-background: yellow;
  $ngx-datatable-selected-active-background-hover: rgba(yellow, 0.2);

  @import '~@swimlane/ngx-datatable/index.css';
  @import '~@swimlane/ngx-datatable/themes/material.scss';
  @import '~@swimlane/ngx-datatable/assets/icons.css';

That's all.
*/
.ngx-datatable.material {
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  /**
  * Shared Styles
  */
  /**
  * Global Row Styles
  */
  /**
  * Header Styles
  */
  /**
  * Body Styles
  */
  /**
  * Footer Styles
  */
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus, .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover, .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus, .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active, .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #fff;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  vertical-align: bottom;
  font-size: 12px;
  font-weight: 500;
}
.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.material .datatable-body {
  position: relative;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-group-header {
  background: #f5f5f5;
  border-bottom: solid 1px #d9d8d9;
  border-top: solid 1px #d9d8d9;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  top: 0;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: rgb(170, 209, 249);
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: rgb(16, 108, 200);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}