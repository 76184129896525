.p-orderlist {
	.p-orderlist-list-container {
		border: 0;
		border-radius: 4px;
	}

	.p-orderlist-list {
		border: 0;

		.p-orderlist-item {
			pointer-events: none;
			overflow: visible;

			p-checkbox {
				pointer-events: all;
			}

			span.p-ink {
				display: none;
			}

			&.p-highlight {
				color: inherit;
				background: transparent;
			}

			&:hover {
				background: #fff;
			}

			&.p-focus {
				background: #fff;
			}
		}
	}

	.p-orderlist-controls {
		display: none;
	}
}

.cdk-drag {
	overflow: visible;
	position: relative;
	padding: 0.7rem;
	margin-left: 45px;

	&.cdk-drag-preview {
		box-shadow: var(--item-selected-box-shadow);
	}

	.drag-marker {
		pointer-events: all;
		cursor: grab;
		height: 30px;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		left: -35px;
	}
}
