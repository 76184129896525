.tabs-wrapper {
	margin-bottom: 1rem;
	position: relative;
}

.p-tablist {
	.p-tablist-tab-list {
		border-color: var(--tab-menu-border-c);

		.p-tab {
			padding: 0.7rem;
			font-size: 1rem;
			color: var(--tab-menu-text-c);
			border-style: solid;
			border-width: 1px;
			border-color: transparent;
			border-bottom-color: var(--tab-menu-border-c);
			box-shadow: none;
			transition: none;
			background-color: var(--tab-menu-link-bgc);

			&.p-tab-active {
				color: var(--tab-menu-text-c);
				border-width: 1px;
				border-color: var(--tab-menu-border-c);
				border-bottom-color: transparent;
				background-color: var(--tab-menu-link-active-bgc);
				border-radius: 4px 4px 0 0;
			}
		}

		.p-tablist-active-bar {
			background-color: transparent;
			border-color: transparent;
		}
	}
}
