.p-fileupload {
	display: flex;
	flex-wrap: nowrap;
	width: 400px;

	&.p-fileupload-advanced {
		border: none;
		position: relative;
	}

	.p-fileupload-header {
		padding: 0;

		p-button {
			display: contents;
		}
	}

	.p-fileupload-buttonbar {
		display: flex;
		justify-content: center;
		padding: 0;
		border-right: 0;
		border-radius: 4px 0 0 4px;
	}

	.p-fileupload-choose-button {
		color: var(--button-primary-text-c);
		background: var(--button-primary-bgc);
		border-color: var(--button-primary-border-c);
		margin: 0;
		border-right: 0;
		border-radius: 4px 0 0 4px;
		height: 100%;
		padding: 0.5rem 1rem;

		.p-iconwrapper {
			color: var(--button-primary-text-c);
		}

		&:not(:disabled):hover {
			color: var(--button-primary-hover-text-c);
			background-color: var(--button-primary-hover-bgc);
			border-color: var(--button-primary-hover-border-c);
		}

		&:not(:disabled):active {
			background-color: var(--button-primary-active-bgc);
			border-color: var(--button-primary-active-border-c);
		}
	}

	p-message,
	p-progressbar {
		display: contents;
	}

	.p-fileupload-content {
		border-left: 0;
		border-radius: 0 4px 4px 0;
		padding: 0.7rem;
		padding-right: 1.8rem;
		width: 1%;
		flex-grow: 1;

		.p-progressbar {
			display: none !important;
		}

		.p-fileupload-file-list {
			.file-name-wrapper {
				.file-name {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				i {
					position: absolute;
					right: 6px;
					top: 50%;
					transform: translate(0, -50%);
					font-size: 1.125rem;
					cursor: pointer;
					color: var(--text-light-grey);

					&:hover {
						color: var(--dark);
					}
				}
			}
		}

		.p-message {
			position: absolute;
			top: 55px;
			left: 0;
			max-width: 400px;
			margin-bottom: 0;

			.p-message-wrapper {
				transition: 0s;
				flex-direction: column;
				padding: 1rem 1rem 1rem 3rem;
				align-items: flex-start;

				.p-message-detail {
					margin-left: 0;
				}
			}
		}
	}
}
