@font-face {
	font-family: 'custom-icons';
	src: url('../fonts/custom-icons.eot?5ehoca');
	src:
		url('../fonts/custom-icons.eot?5ehoca#iefix') format('embedded-opentype'),
		url('../fonts/custom-icons.ttf?5ehoca') format('truetype'),
		url('../fonts/custom-icons.woff?5ehoca') format('woff'),
		url('../fonts/custom-icons.svg?5ehoca#custom-icons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='rfid-ico-']::before,
[class*='rfid-ico-']::before {
	font-family: 'custom-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.rfid-ico-lightspeed:before {
	content: '\e900';
}
.rfid-ico-bigcommerce:before {
	content: '\e901';
}
.rfid-ico-fulfil:before {
	content: '\e902';
}
.rfid-ico-shopify:before {
	content: '\e903';
}
.rfid-ico-printer-outline:before {
	content: '\e90f';
}
.rfid-ico-currency-usd:before {
	content: '\e908';
}
.rfid-ico-calc:before {
	content: '\e90e';
}
.rfid-ico-min-max:before {
	content: '\e907';
}
.rfid-ico-mdi-assets:before {
	content: '\e904';
}
.rfid-ico-mdi-DC:before {
	content: '\e905';
}
.rfid-ico-mdi-supplier:before {
	content: '\e906';
}
.rfid-ico-mdi-cable:before {
	content: '\e909';
}
.rfid-ico-mdi-cable-off:before {
	content: '\e90a';
}
.rfid-ico-mdi-printer-eye:before {
	content: '\e90b';
}
.rfid-ico-mdi-radio-tower-eye:before {
	content: '\e90c';
}
.rfid-ico-mdi-radio-tower-off:before {
	content: '\e90d';
}
.rfid-ico-scan-sessions:before {
	content: '\e910';
}
.rfid-ico-note-edit-outline:before {
	content: '\e911';
}
.rfid-ico-download-XLS:before {
	content: '\e912';
}
.rfid-ico-upload-XLS:before {
	content: '\e913';
}
