@import 'src/app/shared/css/variables';
@import 'src/app/shared/css/mixins';
@import 'src/app/shared/css/confirm-popup';
// remove in redesign
@import 'src/app/shared/css/margins-paddings';
@import 'src/app/shared/css/custom-width';
@import '../node_modules/@swimlane/ngx-datatable/index.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
//
@import '@fontsource-variable/nunito';
@import '@fontsource-variable/nunito/wght.css';
@import '@fontsource-variable/nunito/wght-italic.css';

html {
	font-family: sans-serif;
	position: relative;
	min-height: 100%;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

//scroll for firefox
@-moz-document url-prefix() {
	* {
		scrollbar-color: var(--scroll-thumb-bg) var(--scroll-bar-bg);
		scrollbar-width: thin;
	}
}

*::-webkit-scrollbar {
	width: 5px;
	height: 8px;
	background-color: var(--scroll-bar-bg);
}

*::-webkit-scrollbar-thumb {
	background-color: var(--scroll-thumb-bg);
	border-radius: 9em;
}

body {
	margin: 0;
	font-family: 'Nunito Variable';
	font-size: 0.9rem;
	box-sizing: border-box;
	background-color: var(--site-body-bgc);
	color: var(--secondary);
}

p {
	margin: 0;
}

input:focus-visible {
	box-shadow: none;
	outline: none;
}

.p-component {
	font-family: inherit;
	font-size: inherit;
}

.offline {
	color: var(--offline) !important;
}

.success {
	color: var(--success) !important;
}

.danger {
	color: var(--danger) !important;
}

.warning {
	color: var(--warning) !important;
}

.secondary {
	color: var(--secondary) !important;
}

.light-grey-text {
	color: var(--text-light-grey);
}

hr {
	color: var(--border-c);
	border-color: var(--border-c);
	opacity: 1;
	margin: 0.75rem -12px;

	&.without-margin {
		margin: 0;
	}
}

button {
	padding: 0;
}

small {
	font-size: 0.7rem;
}

.icon-size-normal {
	font-size: 1.3rem;
}

h1 {
	font-size: 2.1rem;
}

h2 {
	font-size: 1.8rem;
}

h3 {
	font-size: 1.25rem;
}

h5 {
	font-size: 1.1rem;
}

h6 {
	font-size: 0.9rem;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
	margin: 0;
	font-weight: 700;
	line-height: 1.2;
}

a,
a:not([href]):not([class]) {
	cursor: pointer;
	color: var(--link-text-c);
	text-decoration: none;
	outline: none;

	&:hover {
		color: var(--link-hover-text-c);
	}
}

ul {
	padding: 0;
	margin: 0;

	li {
		list-style: none;
	}
}

.rotate {
	&-90 {
		rotate: 90deg;
	}

	&-180 {
		rotate: 180deg;
	}

	&-270 {
		rotate: 270deg;
	}
}

.d-none {
	display: none;
}

.text-info-description {
	color: var(--secondary);
	font-size: 0.9rem;

	&.small {
		font-size: 0.8rem;
	}
}

.description {
	p {
		font-weight: 600;
		font-size: 0.9rem;
		margin-bottom: 1rem;
		color: var(--dark);

		&:last-of-type {
			margin-bottom: 0;
		}

		span.label {
			font-weight: 600;
			margin-right: 0.5rem;
			color: var(--text-light-grey);
		}
	}
}

input:disabled {
	color: var(--input-readonly-text-c);
	background-color: var(--input-readonly-bgc);
}

app-search,
labeled-input,
app-input,
p-autocomplete,
p-radiobutton,
customer-selector,
customer-filter,
location-selector,
product-selector,
location-filter,
manager-customer-selector {
	display: contents;
}

.overflow-all-visible {
	overflow: visible !important;
	overflow-y: visible !important;
	overflow-x: visible !important;
}

img,
svg {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
}

.marker {
	position: absolute;
	display: block;
	background-color: var(--marker-default-c);
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	height: 86%;
	width: 6px;
}

.under-construction {
	display: flex;
	align-items: center;
	flex-direction: column;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	background: var(--border-c);
	opacity: 0.5;

	i {
		font-size: 4rem;
	}

	span {
		position: relative;
		color: black;

		&::after {
			content: 'Will be added later';
			bottom: 0px;
			left: -30px;
			position: absolute;
			white-space: nowrap;
		}
	}
}

.justify-center {
	justify-content: center;
}

.cursor-default {
	cursor: default !important;
}

.input-icon-absolute-right {
	position: relative;

	i {
		line-height: 1;
		position: absolute;
		right: 0.5rem;
		top: 50%;
		transform: translate(0, -50%);
	}

	input.control-input {
		padding-right: 2rem;
	}
}

.add-input {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	column-gap: 0.7rem;
}

//progress bar
progress {
	appearance: none;
	width: 100%;
	height: 7px;
	border-radius: 4px;
	background-color: var(--progress-bgc);

	&::-webkit-progress-bar {
		border-radius: 4px;
		background-color: var(--progress-bgc);
	}

	//webkit (chrome, safari)
	&.shipped::-webkit-progress-value {
		border-radius: 4px;
		background-color: var(--progress-value-shipped-bgc);
	}

	&.received::-webkit-progress-value {
		border-radius: 4px;
		background-color: var(--progress-value-received-bgc);
	}

	//firefox
	&.shipped::-moz-progress-bar {
		border-radius: 4px;
		background-color: var(--progress-value-shipped-bgc);
	}

	&.received::-moz-progress-bar {
		border-radius: 4px;
		background-color: var(--progress-value-received-bgc);
	}
}

//loading animations
// spinner
// TODO: replace to 'rfid' spinner component
:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
	animation:
		custom-progress-spinner-dash 1.5s ease-in-out infinite,
		custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
	0% {
		stroke: #16697a;
	}

	40% {
		stroke: #489fb5;
	}

	66% {
		stroke: #82c0cc;
	}

	90% {
		stroke: #ffa62b;
	}
}

@keyframes custom-progress-spinner-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

.small-spiner {
	width: 30px !important;
	height: 30px !important;
	top: 2px;
}

//buttons
button:not(:disabled),
[type='button']:not(:disabled) {
	cursor: pointer;
}

.rfid-btn-list {
	display: flex;
	gap: 0.6rem;
	min-height: 35px;
	flex-wrap: nowrap;
	justify-content: flex-end;

	> span {
		display: inline-block;
	}

	.rfid-btn {
		margin-left: 0.6rem;
	}

	&.list-direction-column {
		flex-direction: column;
	}
}

.icon-list {
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
}

.action-icon,
.action-link {
	cursor: pointer;
	background: transparent;
	border: transparent;
	color: var(--primary);
	font-size: 1.125rem;
	line-height: 1;
	display: inline-flex;
	height: 100%;
	padding: 6px 6px;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	transition: background-color 0.15s ease;

	&:hover {
		color: var(--primary);
		background-color: var(--table-row-action-hover-bgc);
	}
}

.action-link {
	font-size: 0.9rem;
}

.static-icon {
	font-size: 1.125rem;
}

.close-icon {
	line-height: 1;
	font-size: 1.125rem;
	color: var(--close-icon-c);
	cursor: pointer;

	&:hover {
		color: var(--text-dark);
	}
}

// Pages
.page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
	padding-bottom: 4rem;

	footer {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}

footer {
	margin-top: auto;
}

.header-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	column-gap: 2rem;

	.search-with-controls {
		display: flex;
		flex-direction: row;
		column-gap: inherit;
		width: 100%;
	}
}

.rfid-card {
	._description {
		margin-bottom: 0.5rem;
		color: var(--secondary);
		font-size: 0.8rem;
	}

	.card-editable-field {
		min-width: 130px;
		max-width: 130px;
	}

	.group-label {
		font-size: 1rem;
		color: var(--text-light-grey);
		font-weight: 600;
	}
}

//controls
textarea:hover {
	border-color: var(--input-border-hover-c) !important;
}

textarea:focus-visible {
	outline: none;
}

.input-list-column {
	display: flex;
	flex-direction: column;
	row-gap: 0.7rem;
}

.double-control {
	display: flex;
	width: 100%;
	max-width: 600px;
	gap: 0.7rem;
}

.control-input-container {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	height: 35px;
	width: 100%;
	max-width: 300px;
	min-width: 200px;

	.p-focus:focus {
		border: none;
	}

	.p-inputtext:enabled:focus {
		border-color: var(--input-border-hover-c);
	}

	.input-box {
		display: flex;
		flex: 1 1 auto;

		&.readonly {
			> span {
				height: 35px;
				white-space: nowrap;
				padding: 0.4rem 0.8rem;
				border: 1px solid var(--input-readonly-border-c);
				color: var(--input-readonly-text-c);
				background-color: var(--input-readonly-bgc);
				border-left: none;
				border-radius: 0 4px 4px 0;
				width: 0;
				flex: 1 1 auto;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 0.875rem;
			}
		}

		input[type='checkbox'] {
			height: 20px;
			width: 20px;
			border-radius: 4px;
			margin-left: 0.6rem;
		}

		input:not([type='checkbox']):not([type='radio']) {
			width: 0;
			flex: 1 1 auto;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	label {
		white-space: nowrap;
		flex-shrink: 0;
		line-height: 1.7;
		font-weight: 600;
		color: var(--secondary);
		background-color: var(--input-label-bgc);
		padding: 0.28rem 0.8rem;
		height: 100%;
		border: 1px solid var(--input-border-c);
		border-radius: 4px 0 0 4px;

		&::after {
			content: ':';
		}
	}

	&.textarea {
		height: auto;

		textarea {
			width: 100%;
		}
	}
}

.required {
	label {
		&::after {
			content: '' !important;
		}
	}

	span.required-marker {
		&::after {
			content: '*';
			color: var(--input-required-marker-c);
		}

		&::before {
			content: ':';
		}
	}
}

.input-component-wrapper {
	position: relative;
	width: 100%;
	max-width: 300px;
	min-width: 200px;

	.control-input-container {
		max-width: inherit;
		min-width: inherit;
	}
}

.control-input-counter {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	gap: 0.5rem;

	.p-inputnumber-input {
		max-width: 65px;
	}
}

.control-input-checkbox {
	position: relative;
	display: flex;
	align-items: center;
	width: fit-content;

	p-checkbox {
		display: contents;
	}

	label {
		white-space: nowrap;
		padding-left: 27px;
		line-height: 1.4;
	}

	&.disabled {
		label {
			color: var(--text-offline-state);
		}
	}

	.p-checkbox,
	.p-radiobutton {
		position: absolute;
	}

	.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) {
		.p-radiobutton-box {
			border-color: var(--input-border-hover-c);
		}
	}

	i.mdi-close {
		margin-left: 0.2rem;
	}
}

.p-radiobutton-checked {
	.p-radiobutton-box {
		border-color: var(--checkbox-checked-border-c);
		background: var(--checkbox-checked-bgc);
	}
}

.p-checkbox-checked {
	.p-checkbox-box {
		border-color: var(--checkbox-checked-border-c);
		background: var(--checkbox-checked-bgc);
	}
}

.checkbox-list {
	display: flex;
	gap: 1.5rem;
}

.control-input:not([type='checkbox']) {
	min-height: 35px;
	padding: 0.28rem 0.8rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0 4px 4px 0;
	border: 1px solid var(--input-border-c);
	border-left: none;
	transition: border-color 0.2s ease;

	&:not([disabled]):hover {
		border-color: var(--input-border-hover-c);
	}

	&.without-label {
		border-left: 1px solid var(--input-border-c);
		border-radius: 4px;
	}
}

.p-select,
.p-multiselect {
	border: 1px solid var(--input-border-c);
	border-left: none;
	border-radius: 0 4px 4px 0;
	height: 35px;
	width: 0;
	flex: 1 1 auto;
	align-items: center;

	&.p-disabled {
		background: var(--input-readonly-bgc);

		span {
			line-height: 1;
			color: var(--input-readonly-text-c);
			background-color: var(--input-readonly-bgc);
		}
	}

	.p-inputtext,
	input {
		font: inherit !important;
	}

	&:not(:disabled):hover {
		border-color: var(--input-border-hover-c);
	}

	.p-multiselect-filter-container {
		display: flex;
	}
}

.p-autocomplete {
	height: 35px;
	width: 0;
	flex: 1 1 auto;
	align-items: center;

	.p-autocomplete-input {
		border-radius: 0 4px 4px 0;
		padding-right: 2.2rem;
		border-left: 0;
		height: inherit;
	}

	.p-autocomplete-multiple-container {
		width: 100%;
	}

	&.p-autocomplete-clearable {
		.p-inputtext {
			padding-right: 3rem;
		}
	}
}

.p-inputtext:enabled:hover {
	border-color: var(--input-border-hover-c);
}

.p-autocomplete-dropdown {
	background-color: transparent;
	border: none;
	border-radius: 0;
}

.p-autocomplete-dropdown,
.p-multiselect-dropdown,
.p-select-dropdown {
	position: absolute;
	right: 8px;
	width: auto;
	color: var(--internal-button-c);

	&:hover {
		color: var(--internal-button-hover-c);
		background-color: var(--internal-button-bgc);
	}

	&:not(:disabled):active,
	&:not(:disabled):focus {
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.p-icon {
		height: 14px;
		width: 14px;
	}
}

.p-multiselect-clear-icon,
.p-autocomplete-clear-icon {
	position: absolute;
	right: 27px;
	height: 14px;
	width: 14px;
	color: var(--internal-button-c);

	&:hover {
		color: var(--internal-button-hover-c);
		background-color: var(--internal-button-bgc);
	}

	&:not(:disabled):active,
	&:not(:disabled):focus {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

.p-select-overlay {
	.p-select-list {
		.p-select-option {
			color: var(--input-dropdown-option-text-c);

			&:not(.p-disabled) {
				&:hover {
					background: var(--input-dropdown-option-hover-bgc);
				}

				&.p-select-option-selected:hover {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}

			&.p-select-option-selected {
				background: var(--item-keyboard-select-bgc);

				&.p-focus {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}
		}
	}
}

.p-autocomplete-overlay {
	width: 0;
	flex-grow: 1;

	.p-autocomplete-list {
		.p-autocomplete-option-group {
			font-weight: 700;
		}

		.p-autocomplete-option {
			color: var(--input-dropdown-option-text-c);

			&:not(.p-disabled) {
				&:hover {
					background: var(--input-dropdown-option-hover-bgc);
				}

				&.p-autocomplete-option-selected:hover {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}

			&.p-autocomplete-option-selected {
				background: var(--item-keyboard-select-bgc);

				&.p-focus {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}

			span {
				word-break: break-word;
				white-space: normal;
			}
		}
	}
}

.p-multiselect-overlay {
	.p-multiselect-header {
		.p-multiselect-filter-container {
			p-iconfield {
				display: flex;
				flex: 1 1 auto;
			}
		}
	}

	.p-multiselect-option-list {
		.p-multiselect-option {
			color: var(--input-dropdown-option-text-c);

			&:not(.p-disabled) {
				&:hover {
					background: var(--input-dropdown-option-hover-bgc);
				}

				&.p-multiselect-option-selected:hover {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}

			&.p-multiselect-option-selected {
				background: var(--item-keyboard-select-bgc);

				&.p-focus {
					background: var(--item-keyboard-select-hover-bgc);
				}
			}
		}
	}
}

.p-select-overlay,
.p-autocomplete-overlay,
.p-multiselect-overlay {
	border-radius: 4px;
}

.without-label,
.label-top {
	.input-box {
		> input,
		> textarea {
			border-left: 1px solid var(--input-border-c);
			border-radius: 4px;
		}

		> textarea {
			border-left: 1px solid var(--input-border-c);
			border-radius: 4px;
		}

		&.readonly {
			span {
				border-left: 1px solid var(--input-border-c);
				border-radius: 4px;
			}
		}
	}

	.p-select,
	.p-autocomplete {
		border-radius: 4px;
	}

	.p-select {
		border-left: 1px solid var(--input-border-c);

		&:hover {
			border-color: var(--input-border-hover-c);
		}
	}

	.p-autocomplete {
		.p-autocomplete-input {
			border-left: 1px solid var(--input-border-c);
			border-radius: 4px;

			&:hover {
				border-color: var(--input-border-hover-c);
			}
		}
	}

	.p-multiselect {
		border-left: 1px solid var(--input-border-c);
		border-radius: 4px;

		&:not(.p-disabled):hover {
			border-color: var(--input-border-hover-c);
		}
	}

	.skeleton {
		width: 100%;
		border-radius: 4px !important;
		border-left: 1px solid var(--input-border-c) !important;
	}
}

.label-top {
	margin-top: 1.3rem;

	.control-input-container,
	&.control-input-container {
		margin-top: 1.3rem;
	}

	label {
		height: auto;
		position: absolute;
		top: -21px;
		left: 0;
		line-height: 1;
		padding: 0;
		background: inherit;
		border: 0;
		color: var(--input-label-text-c);
	}
}

.switcher-container {
	display: flex;
	flex-wrap: nowrap;
	height: fit-content;
	column-gap: 0.7rem;

	label {
		position: relative;
		max-width: 180px;
	}

	.p-toggleswitch {
		width: 2.5rem;
		height: 1.25rem;
		vertical-align: text-top;

		&.p-toggleswitch-checked {
			.p-toggleswitch-slider {
				background: var(--toggle-switch-checked-bgc);
				border-color: var(--toggle-switch-checked-border-c);
			}
		}
	}
}

.p-overlay {
	.p-overlay-content {
		display: flex;
	}
}

.p-popover {
	border: 1px solid var(--border-c);
	box-shadow: var(--box-shadow-overlay-p);

	&::before {
		border-bottom-color: var(--border-c);
	}

	.p-popover-content {
		width: 400px;
	}

	&.custom-position {
		margin-left: 0;
		margin-bottom: 10px;

		&::before {
			top: -8px;
			right: 25px;
			left: auto;
			rotate: 45deg;
			bottom: auto;
			width: 15px;
			height: 15px;
			border: 1px solid black;
			border-color: #c9ceda transparent transparent #c9ceda;
			background-color: #fff;
		}

		&::after {
			top: 8px;
			right: 23px;
			rotate: 180deg;
			bottom: auto;
			border-color: transparent;
		}
	}
}

.overlay-custom-position-wrapper {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	width: 0;
	flex: 1 1 auto;

	.overlay-target {
		height: 0;
		position: absolute;
		right: 0;
		top: 100%;
	}
}

.p-fileupload {
	.p-fileupload-header {
		padding: 0;

		p-button {
			display: contents;
		}
	}

	.p-fileupload-choose-button {
		color: var(--button-primary-text-c);
		background: var(--button-primary-bgc);
		border-color: var(--button-primary-border-c);
		margin: 0;
		border-right: 0;
		border-radius: 4px 0 0 4px;
		height: 100%;
		padding: 0.5rem 1rem;

		.p-iconwrapper {
			color: var(--button-primary-text-c);
		}

		&:not(:disabled):hover {
			color: var(--button-primary-hover-text-c);
			background-color: var(--button-primary-hover-bgc);
			border-color: var(--button-primary-hover-border-c);
		}

		&:not(:disabled):active {
			background-color: var(--button-primary-active-bgc);
			border-color: var(--button-primary-active-border-c);
		}
	}
}

//table
.table-wrapper {
	height: 0;
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
}

.table-error {
	.p-inline-message-text {
		white-space: nowrap !important;
	}
}

p-table {
	display: contents;
}

.p-datatable {
	height: 100%;

	.p-datatable-table-container {
		height: 100%;
		overflow: auto;

		.p-datatable-thead {
			position: sticky;
			top: 0;
			white-space: nowrap;
			z-index: 12;

			.p-iconwrapper:has(.p-sortable-column-icon) {
				display: none;
			}

			tr {
				th {
					font-weight: 600;
					padding: 0.4rem 0.6rem;
					vertical-align: middle;
					color: var(--table-header-text-c);
					background-color: var(--table-header-bgc);

					&.icon-only {
						i {
							padding: 0 3px;
						}
					}

					&.p-sortable-column {
						.p-sortable-column-icon {
							margin-bottom: -0.2rem;
							margin-left: 0;

							i {
								line-height: 1;
							}
						}
					}

					&.clm-Image {
						width: 0%;
						min-width: 60px;
					}
				}

				&:nth-child(2) {
					th {
						background-color: var(--table-second-header-bgc);
						border: none;
					}
				}
			}
		}

		.p-datatable-tbody {
			tr {
				height: 45px;

				td {
					color: var(--table-row-text-c);
					border-bottom: 1px solid var(--table-row-border-c);
					vertical-align: middle;
					padding: 0.4rem 0.6rem;

					div.table-img-box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 40px;
						height: 40px;
						border: 1px solid var(--table-row-img-box-border-c);
						border-radius: 4px;
						background: var(--light);
						overflow: hidden;

						img {
							max-width: 100%;
							max-height: 100%;
							object-fit: contain;
						}

						i {
							font-size: 1.5rem;
							display: flex;
							height: 100%;
							align-items: center;
							color: var(--table-row-img-box-icon-c);
						}
					}

					&.row-action-wrapper {
						.row-action {
							cursor: pointer;
							color: var(--table-row-action-text-c);
							font-size: 1.125rem;
							line-height: 1;
							display: inline-flex;
							height: 100%;
							padding: 6px 6px;
							align-items: center;
							justify-content: center;
							border-radius: 25%;
							transition: background-color 0.15s ease;

							&.--text-fz {
								font-size: 0.9rem;
							}

							&:hover {
								color: var(--table-row-action-hover-text-c);
								background-color: var(--table-row-action-hover-bgc);
							}

							&.active {
								background-color: var(--table-row-action-selected-bgc);
							}

							&:nth-child(n + 2) {
								margin-left: 0.2rem;
							}
						}

						.expand-chevron {
							line-height: 0.8;
							font-size: 1.4rem;
						}

						.static-icon {
							padding: 0 6px;
						}
					}
				}

				&:not(.expanded-row):hover {
					td {
						color: var(--table-row-hover-text-c);
					}

					background-color: var(--table-row-hover-bgc);

					.row-action-wrapper {
						.row-action {
							color: var(--table-row-action-hover-text-c);
						}
					}
				}

				&.expanded-row {
					box-shadow:
						0px 9px 5px -5px #3858a61f inset,
						0px -8px 5px -5px #3858a61f inset;

					> td {
						padding: 0;
					}

					.expanded-row-content {
						padding: 0.5rem 0;
					}
				}

				&.no-hover:hover {
					background-color: transparent;
				}

				&.row-selected {
					-webkit-box-shadow: var(--item-selected-box-shadow);
					-moz-box-shadow: var(--item-selected-box-shadow);
					box-shadow: var(--item-selected-box-shadow);
					background: var(--item-selected-bgc) !important;
					color: inherit !important;
					position: relative;
					z-index: 10;
				}

				&:last-of-type {
					td {
						border-bottom: none;
					}
				}
			}
		}

		i {
			font-size: 1.125rem;
			display: inline-block;
		}
	}

	&.row-select {
		.p-datatable-tbody {
			tr:not(.expanded-row) {
				cursor: pointer;
			}
		}
	}
}

.table-header-selection,
.table-row-selection {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	column-gap: 0.5rem;

	p-tableheadercheckbox,
	p-tableCheckbox {
		display: contents;
	}

	label {
		line-height: 1;
	}
}

//tooltips
.p-tooltip {
	text-align: center;
	padding: 0;

	.p-tooltip-arrow {
		scale: 1;
		position: absolute;
		width: 0.482rem;
		height: 0.482rem;
		rotate: 45deg;
		background: var(--tooltip-bgc);
		border: none;

		&::after {
			content: '';
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: transparent;
			border: 1px solid transparent;
		}
	}

	&.p-tooltip-right {
		padding-left: 0.34rem;

		.p-tooltip-arrow {
			top: 50%;
			left: 2px;

			&::after {
				border-color: transparent transparent var(--tooltip-border-c) var(--tooltip-border-c);
			}
		}
	}

	&.p-tooltip-left {
		padding-right: 0.34rem;

		.p-tooltip-arrow {
			top: 50%;
			right: 2px;

			&::after {
				border-color: var(--tooltip-border-c) var(--tooltip-border-c) transparent transparent;
			}
		}
	}

	&.p-tooltip-top {
		padding-bottom: 0.34rem;

		.p-tooltip-arrow {
			bottom: 2px;
			left: 50%;

			&::after {
				border-color: transparent var(--tooltip-border-c) var(--tooltip-border-c) transparent;
			}
		}
	}

	&.p-tooltip-bottom {
		padding-top: 0.34rem;

		.p-tooltip-arrow {
			top: 2px;
			left: 50%;

			&::after {
				border-color: var(--tooltip-border-c) transparent transparent var(--tooltip-border-c);
			}
		}
	}

	.p-tooltip-text {
		border: 1px solid var(--tooltip-border-c);
		border-radius: 4px;
		padding: 0.25rem 0.5rem;
		box-shadow: none;
		background-color: var(--tooltip-bgc);
		color: var(--tooltip-text-c);
	}
}

//delimiter
.delimiter {
	position: relative;
	min-height: 37px;
	background-color: var(--site-body-bgc);
	margin: 0.9rem -0.75rem;
	padding: 0.5rem;
	box-shadow: var(--box-shadow-delimiter);

	&[data-height-0] {
		min-height: 0;
	}
}

//Bages
.badge-container {
	display: flex;
	flex-wrap: wrap;
	row-gap: 0.5rem;
	column-gap: 0.5rem;
	width: 100%;
}

.badge {
	font-weight: 600;
	padding: 0.35rem 0.6rem;
	line-height: 1;
	font-size: 0.8rem;
	text-align: center;
	border-radius: 4px;
	white-space: nowrap;
	height: 1.5rem;
	color: var(--text-secondary);

	//add position to corner badge (top, bottom => left/right)
	&.corner-badge {
		position: absolute;
		padding: 0.35rem;
		border-radius: 0;

		&.top-left {
			left: 0;
			top: 0;
			border-bottom-right-radius: 50%;
			border-top-left-radius: 4px;
		}
	}

	&.badge-grey {
		color: var(--badge-grey-text-c);
		background-color: var(--badge-grey-bgc);
	}

	&.badge-warning {
		color: var(--badge-warning-text-c);
		background-color: var(--badge-warning-bgc);
	}

	&.badge-info {
		color: var(--badge-info-text-c);
		background-color: var(--badge-info-bgc);
	}

	&.badge-dark-blue {
		color: var(--badge-dark-blue-text-c);
		background-color: var(--badge-dark-blue-bgc);
	}

	&.badge-danger {
		color: var(--badge-danger-text-c);
		background-color: var(--badge-danger-bgc);
	}

	&.badge-primary {
		color: var(--badge-primary-text-c);
		background-color: var(--badge-primary-bgc);
	}

	&.badge-sld-layout {
		color: var(--badge-sld-layout-text-c);
		background-color: var(--badge-sld-layout-bgc);
	}

	&.badge-zpl-layout {
		color: var(--badge-zpl-layout-text-c);
		background-color: var(--badge-zpl-layout-bgc);
	}
}

//text prop
.t-right {
	text-align: right !important;
}

.t-left {
	text-align: left !important;
}

.t-center {
	text-align: center !important;
}

.t-nowrap {
	white-space: nowrap !important;
}

//pop-up
.p-dialog {
	border-radius: 4px;

	.p-dialog-content {
		padding: 0.75rem;
	}

	.p-dialog-header {
		border-bottom: 1px solid var(--border-c);
		padding: 0.9rem;

		.p-dialog-title {
			font-weight: 700;
			font-size: 1.5rem;
		}

		.p-dialog-close-button {
			width: 30px;
			height: 30px;
			background-color: var(--internal-button-bgc);
			color: var(--internal-button-c);

			&:hover {
				color: var(--internal-button-hover-c);
			}
		}
	}

	.p-dialog-footer {
		padding: 0.75rem;
		padding-top: 0;

		button {
			margin-right: 0;
		}
	}
}

//sidebar
.sidebar-container,
.p-drawer {
	box-shadow: var(--box-shadow-sidebar);

	.p-drawer-header {
		padding: 1rem;
		background-color: var(--light);
		justify-content: space-between;
	}

	.p-drawer-content {
		display: flex;
		flex: 1 1 auto;
		position: static;
		flex-direction: column;
		padding-top: 1rem;

		.filters-container {
			display: flex;
			flex-direction: column;
			row-gap: 1.5rem;
		}
	}

	.closeButton {
		.p-button {
			width: 30px;
			height: 30px;
			background-color: var(--internal-button-bgc);
			color: var(--internal-button-c);

			&:hover {
				color: var(--internal-button-hover-c);
			}
		}
	}

	.sidebar-button {
		&.bottom-center {
			margin-top: auto;
			text-align: center;
		}
	}
}

//Tabs
.tabs-wrapper {
	margin-bottom: 1rem;
	position: relative;
}

.p-tablist {
	.p-tablist-tab-list {
		border-color: var(--tab-menu-border-c);

		.p-tab {
			padding: 0.7rem;
			font-size: 1rem;
			color: var(--tab-menu-text-c);
			border-style: solid;
			border-width: 1px;
			border-color: transparent;
			border-bottom-color: var(--tab-menu-border-c);
			box-shadow: none;
			transition: none;
			background-color: var(--tab-menu-link-bgc);

			&.p-tab-active {
				color: var(--tab-menu-text-c);
				border-width: 1px;
				border-color: var(--tab-menu-border-c);
				border-bottom-color: transparent;
				background-color: var(--tab-menu-link-active-bgc);
				border-radius: 4px 4px 0 0;
			}
		}

		.p-tablist-active-bar {
			background-color: transparent;
			border-color: transparent;
		}
	}
}

// error messages
.has-error-message {
	.p-message {
		display: none;
		position: absolute;
		z-index: 1000000;
	}

	.pos-bottom {
		.p-message {
			left: 0;
			top: calc(100% + 5px);
		}
	}

	.pos-top {
		.p-message {
			left: 0;
			bottom: calc(100% + 5px);
		}
	}

	.pos-left {
		.p-message {
			top: 50%;
			transform: translate(0, -50%);
			right: calc(100% + 10px);
		}
	}

	.pos-right {
		.p-message {
			top: 50%;
			transform: translate(0, -50%);
			left: calc(100% + 10px);
		}
	}

	&:hover {
		.p-message {
			display: flex;
			width: max-content;
			max-width: 300px;
		}
	}

	label {
		border-color: var(--danger) var(--border-c) var(--danger) var(--danger) !important;
	}

	input,
	textarea {
		border-color: var(--danger) !important;
	}

	.input-box {
		> span {
			height: 35px;
			line-height: 1.5;
			border-color: var(--danger) !important;
			padding: 0.4rem 0.8rem;
			border-radius: 4px;
		}
	}
}

.p-message {
	outline: none;

	.p-message-content {
		padding: 0.5rem;
		position: relative;
	}

	.p-message-text {
		word-break: break-word;
		white-space: normal;
		line-height: 1.4;
		font-size: 0.8rem;
		font-weight: 400;
	}

	.p-message-icon {
		font-size: 1.125rem;
		margin: 0;
		position: absolute;
		top: 50%;
		transform: translate(0, -35%);
		left: 12px;
	}

	&.p-message-error {
		color: var(--message-detail-text-c);
		background-color: var(--message-error-bgc);
		border-radius: 4px;
		border-left: 3px solid var(--message-error-border-c);

		.p-message-icon {
			color: var(--message-error-icon-c);
		}
	}

	&.p-message-success {
		margin: 0;
		color: var(--message-detail-text-c);
		background-color: var(--message-success-bgc);
		border-radius: 4px;
		border-left: 3px solid var(--message-success-border-c);

		.p-message-icon {
			color: var(--message-success-icon-c);
		}
	}

	.p-message-close {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		right: 8px;

		.p-icon {
			color: var(--message-close-icon-c);
			width: 1rem;
			height: 1rem;
		}
	}
}

// Accordeon
.p-accordion {
	.p-icon-wrapper {
		.p-icon {
			width: 0.8rem;
			height: 0.8rem;
		}
	}

	.p-accordionpanel {
		border-bottom: 1px solid var(--border-c);
	}

	.p-accordionpanel-active {
		.p-accordionheader {
			background-color: var(--item-selected-bgc);
		}
	}

	.p-accordionheader {
		padding: 0.7rem;
		color: var(--text-secondary);
		background-color: $white;

		&:hover {
			background-color: var(--line-hover-bgc);
			color: var(--secondary);
		}
	}

	.p-accordion-content {
		padding: 0.5rem;

		ul {
			padding: 0.3rem;

			li {
				white-space: nowrap;
				padding: 0.3rem;
				display: flex;
				align-items: center;
				height: 35px;
			}
		}

		i {
			padding: 0 5px;
			vertical-align: middle;
		}
	}
}

//Toast
.p-toast {
	.p-toast-summary {
		color: var(--message-summary-text-c);
	}

	.p-toast-detail {
		color: var(--message-detail-text-c);
	}

	.p-toast-message-icon {
		display: flex;
	}

	.p-toast-icon-close {
		.p-icon {
			color: var(--message-close-icon-c);
		}
	}

	.p-toast-message-error {
		background: var(--message-error-bgc);
		border: solid var(--message-error-border-c);
		border-width: 0 0 0 6px;

		.p-toast-message-icon {
			font-size: 1.5rem;
			color: var(--message-error-icon-c);
		}
	}

	.p-toast-message-success {
		background: var(--message-success-bgc);
		border: solid var(--message-success-border-c);
		border-width: 0 0 0 6px;

		.p-toast-message-icon {
			font-size: 1.5rem;
			color: var(--message-success-icon-c);
		}
	}

	.p-toast-message-warn {
		background: var(--message-warning-bgc);
		border: solid var(--message-warning-border-c);
		border-width: 0 0 0 6px;

		.p-toast-message-icon {
			font-size: 1.5rem;
			color: var(--message-warning-icon-c);
		}
	}

	.p-toast-message-content {
		padding: 0.7rem;
		align-items: center;

		.p-toast-close-button {
			background-color: var(--internal-button-bgc);
			color: var(--internal-button-c);

			&:hover {
				color: var(--internal-button-hover-c);
			}
		}
	}

	&.header-only {
		.p-toast-detail {
			display: none;
		}
	}
}

//Order list (draggable)
.p-orderlist {
	.p-orderlist-list-container {
		border: 0;
		border-radius: 4px;
	}

	.p-orderlist-list {
		border: 0;

		.p-orderlist-item {
			pointer-events: none;
			overflow: visible;

			p-checkbox {
				pointer-events: all;
			}

			span.p-ink {
				display: none;
			}

			&.p-highlight {
				color: inherit;
				background: transparent;
			}

			&:hover {
				background: #fff;
			}

			&.p-focus {
				background: #fff;
			}
		}
	}

	.p-orderlist-controls {
		display: none;
	}
}

.cdk-drag {
	overflow: visible;
	position: relative;
	padding: 0.7rem;
	margin-left: 45px;

	&.cdk-drag-preview {
		box-shadow: var(--item-selected-box-shadow);
	}

	.drag-marker {
		pointer-events: all;
		cursor: grab;
		height: 30px;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		left: -35px;
	}
}
