//Inline messages

.has-error-message {
	.p-message {
		display: none;
		position: absolute;
		z-index: 1000000;
	}

	.pos-bottom {
		.p-message {
			left: 0;
			top: calc(100% + 5px);
		}
	}

	.pos-top {
		.p-message {
			left: 0;
			bottom: calc(100% + 5px);
		}
	}

	.pos-left {
		.p-message {
			top: 50%;
			transform: translate(0, -50%);
			right: calc(100% + 10px);
		}
	}

	.pos-right {
		.p-message {
			top: 50%;
			transform: translate(0, -50%);
			left: calc(100% + 10px);
		}
	}

	&:hover {
		.p-message {
			display: flex;
			width: max-content;
			max-width: 300px;
		}
	}

	label {
		border-color: var(--danger) var(--border-c) var(--danger) var(--danger) !important;
	}

	input,
	textarea {
		border-color: var(--danger) !important;
	}

	.input-box {
		> span {
			height: 35px;
			line-height: 1.5;
			border-color: var(--danger) !important;
			padding: 0.4rem 0.8rem;
			border-radius: 4px;
		}
	}
}

.p-message {
	margin: 0;
	outline: none;

	.p-message-content {
		padding: 0.5rem;
		position: relative;
	}

	.p-message-text {
		word-break: break-word;
		white-space: normal;
		line-height: 1.4;
		font-size: 0.8rem;
		font-weight: 400;
	}

	.p-message-icon {
		font-size: 1.125rem;
		margin: 0;
		position: absolute;
		top: 50%;
		transform: translate(0, -35%);
		left: 12px;
	}

	&.p-message-error {
		color: var(--message-detail-text-c);
		background-color: var(--message-error-bgc);
		border-radius: 4px;
		border-left: 3px solid var(--message-error-border-c);

		.p-message-icon {
			color: var(--message-error-icon-c);
		}
	}

	&.p-message-success {
		margin: 0;
		color: var(--message-detail-text-c);
		background-color: var(--message-success-bgc);
		border-radius: 4px;
		border-left: 3px solid var(--message-success-border-c);

		.p-message-icon {
			color: var(--message-success-icon-c);
		}
	}

	.p-message-close {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		right: 8px;

		.p-icon {
			color: var(--message-close-icon-c);
			width: 1rem;
			height: 1rem;
		}
	}
}

//Toast messages
.p-toast {
	.p-toast-summary {
		color: var(--message-summary-text-c);
	}

	.p-toast-detail {
		color: var(--message-detail-text-c);
	}

	.p-toast-message-icon {
		display: flex;
	}

	.p-toast-icon-close {
		.p-icon {
			color: var(--message-close-icon-c);
		}
	}

	.p-toast-message-error {
		background: var(--message-error-bgc);
		border: solid var(--message-error-border-c);
		border-width: 0 0 0 6px;

		.p-toast-message-icon {
			font-size: 1.5rem;
			color: var(--message-error-icon-c);
		}
	}

	.p-toast-message-success {
		background: var(--message-success-bgc);
		border: solid var(--message-success-border-c);
		border-width: 0 0 0 6px;

		.p-toast-message-icon {
			font-size: 1.5rem;
			color: var(--message-success-icon-c);
		}
	}

	.p-toast-message-warn {
		background: var(--message-warning-bgc);
		border: solid var(--message-warning-border-c);
		border-width: 0 0 0 6px;

		.p-toast-message-icon {
			font-size: 1.5rem;
			color: var(--message-warning-icon-c);
		}
	}

	.p-toast-message-content {
		padding: 0.7rem;
		align-items: center;

		.p-toast-close-button {
			background-color: var(--internal-button-bgc);
			color: var(--internal-button-c);

			&:hover {
				color: var(--internal-button-hover-c);
			}
		}
	}

	&.header-only {
		.p-toast-detail {
			display: none;
		}
	}
}
