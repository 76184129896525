.p-accordion {
	.p-icon-wrapper {
		.p-icon {
			width: 0.8rem;
			height: 0.8rem;
		}
	}

	.p-accordionpanel {
		border-bottom: 1px solid var(--border-c);
	}

	.p-accordionpanel-active {
		.p-accordionheader {
			background-color: var(--item-selected-bgc);
		}
	}

	.p-accordionheader {
		padding: 0.7rem;
		color: var(--text-secondary);
		background-color: var(--white);

		&:hover {
			background-color: var(--line-hover-bgc);
			color: var(--secondary);
		}
	}

	.p-accordion-content {
		padding: 0.5rem;

		ul {
			padding: 0.3rem;

			li {
				white-space: nowrap;
				padding: 0.3rem;
				display: flex;
				align-items: center;
				height: 35px;
			}
		}

		i {
			padding: 0 5px;
			vertical-align: middle;
		}
	}
}
