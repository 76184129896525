//percentage
.pw-100 {
	width: 100% !important;
}

// px in rem

// width
.w-150 {
	width: 9.375rem;
}

//min width
.minw-150 {
	min-width: 9.375rem;
}

//max width
.maxw-180 {
	max-width: 11.25rem !important;
}

.min-maxw250 {
	max-width: 15.625rem !important;
	min-width: 15.625rem !important;
}

.maxw-150 {
	max-width: 9.375rem;
}
