.table-wrapper {
	height: 0;
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
}

.table-error {
	.p-inline-message-text {
		white-space: nowrap !important;
	}
}

p-table {
	display: contents;
}

.p-datatable {
	height: 100%;

	.p-datatable-table-container {
		height: 100%;
		overflow: auto;

		.p-datatable-thead {
			position: sticky;
			top: 0;
			white-space: nowrap;
			z-index: 12;

			.p-iconwrapper:has(.p-sortable-column-icon) {
				display: none;
			}

			tr {
				th {
					font-weight: 600;
					padding: 0.4rem 0.6rem;
					vertical-align: middle;
					color: var(--table-header-text-c);
					background-color: var(--table-header-bgc);

					&.icon-only {
						i {
							padding: 0 3px;
						}
					}

					&.p-sortable-column {
						.p-sortable-column-icon {
							margin-bottom: -0.2rem;
							margin-left: 0;

							i {
								line-height: 1;
							}
						}
					}

					&.clm-Image {
						width: 0%;
						min-width: 60px;
					}
				}

				&:nth-child(2) {
					th {
						background-color: var(--table-second-header-bgc);
						border: none;
					}
				}
			}
		}

		.p-datatable-tbody {
			tr {
				height: 45px;

				td {
					color: var(--table-row-text-c);
					border-bottom: 1px solid var(--table-row-border-c);
					vertical-align: middle;
					padding: 0.4rem 0.6rem;

					div.table-img-box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 40px;
						height: 40px;
						border: 1px solid var(--table-row-img-box-border-c);
						border-radius: 4px;
						background: var(--light);
						overflow: hidden;

						img {
							max-width: 100%;
							max-height: 100%;
							object-fit: contain;
						}

						i {
							font-size: 1.5rem;
							display: flex;
							height: 100%;
							align-items: center;
							color: var(--table-row-img-box-icon-c);
						}
					}

					&.row-action-wrapper {
						.row-action {
							cursor: pointer;
							color: var(--table-row-action-text-c);
							font-size: 1.125rem;
							line-height: 1;
							display: inline-flex;
							height: 100%;
							padding: 6px 6px;
							align-items: center;
							justify-content: center;
							border-radius: 25%;
							transition: background-color 0.15s ease;

							&.--text-fz {
								font-size: 0.9rem;
							}

							&:hover {
								color: var(--table-row-action-hover-text-c);
								background-color: var(--table-row-action-hover-bgc);
							}

							&.active {
								background-color: var(--table-row-action-selected-bgc);
							}

							&:nth-child(n + 2) {
								margin-left: 0.2rem;
							}
						}

						.expand-chevron {
							line-height: 0.8;
							font-size: 1.4rem;
						}

						.static-icon {
							padding: 0 6px;
						}
					}
				}

				&:not(.expanded-row):hover {
					td {
						color: var(--table-row-hover-text-c);
					}

					background-color: var(--table-row-hover-bgc);

					.row-action-wrapper {
						.row-action {
							color: var(--table-row-action-hover-text-c);
						}
					}
				}

				&.expanded-row {
					box-shadow:
						0px 9px 5px -5px #3858a61f inset,
						0px -8px 5px -5px #3858a61f inset;

					> td {
						padding: 0;
					}

					.expanded-row-content {
						padding: 0.5rem 0;
					}
				}

				&.no-hover:hover {
					background-color: transparent;
				}

				&.row-selected {
					-webkit-box-shadow: var(--item-selected-box-shadow);
					-moz-box-shadow: var(--item-selected-box-shadow);
					box-shadow: var(--item-selected-box-shadow);
					background: var(--item-selected-bgc) !important;
					color: inherit !important;
					position: relative;
					z-index: 10;
				}

				&:last-of-type {
					td {
						border-bottom: none;
					}
				}
			}
		}

		i {
			font-size: 1.125rem;
			display: inline-block;
		}
	}

	&.row-select {
		.p-datatable-tbody {
			tr:not(.expanded-row) {
				cursor: pointer;
			}
		}
	}
}

.table-header-selection,
.table-row-selection {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	column-gap: 0.5rem;

	p-tableheadercheckbox,
	p-tableCheckbox {
		display: contents;
	}

	label {
		line-height: 1;
	}
}
