.sidebar-container,
.p-drawer {
	box-shadow: var(--box-shadow-sidebar);

	.p-drawer-header {
		padding: 1rem;
		background-color: var(--light);
		justify-content: space-between;
	}

	.p-drawer-content {
		display: flex;
		flex: 1 1 auto;
		position: static;
		flex-direction: column;
		padding-top: 1rem;

		.filters-container {
			display: flex;
			flex-direction: column;
			row-gap: 1.5rem;
		}
	}

	.closeButton {
		.p-button {
			width: 30px;
			height: 30px;
			background-color: var(--internal-button-bgc);
			color: var(--internal-button-c);

			&:hover {
				color: var(--internal-button-hover-c);
			}
		}
	}

	.sidebar-button {
		&.bottom-center {
			margin-top: auto;
			text-align: center;
		}
	}
}
