.p-dialog {
	border-radius: 4px;

	.p-dialog-content {
		padding: 0.75rem;
	}

	.p-dialog-header {
		border-bottom: 1px solid var(--border-c);
		padding: 0.9rem;

		.p-dialog-title {
			font-weight: 700;
			font-size: 1.5rem;
		}

		.p-dialog-close-button {
			width: 30px;
			height: 30px;
			background-color: var(--internal-button-bgc);
			color: var(--internal-button-c);

			&:hover {
				color: var(--internal-button-hover-c);
			}
		}
	}

	.p-dialog-footer {
		padding: 0.75rem;
		padding-top: 0;

		button {
			margin-right: 0;
		}
	}
}
