//Styles for cards (Need to move here from other components and partially refactor)

.rfid-card {
	._description {
		margin-bottom: 0.5rem;
		color: var(--secondary);
		font-size: 0.8rem;
	}

	.card-editable-field {
		min-width: 130px;
		max-width: 130px;
	}

	.group-label {
		font-size: 1rem;
		color: var(--text-light-grey);
		font-weight: 600;
	}
}
